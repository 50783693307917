import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Results } from './components';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch, useGetMarketingSummaryReport, useGetMarketingTree } from 'utils';
import {typeDateReport} from 'components/MarketingSummaryFilter/components/Filter/Filter'
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
  //   marginTop: 10
  }
}));

const MarketingSummary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fromDate, toDate, oid, pid, sid, eid, type, leaseProvider, storeType } = useFiltersSearch();
  const marketingTreeData = useGetMarketingTree();
  const marketingTreeList = marketingTreeData.marketingList;
  const {ledgerListCount, ledgerList, inProgress, dataReloadInit} = useGetMarketingSummaryReport();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      fromDate: fromDate || null,
      toDate: toDate || null,
      oid: oid || '',
      pid: pid || '',
      sid: sid || '',
      eid: eid || '',
      type: type || typeDateReport[0]?.value,
      leaseProvider: leaseProvider || '',
      storeType: storeType || ''
    }
  });

  useEffect(() => {
    const payload = {
      main_title: 'Reports',
      secondary_title: 'Marketing Summary',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Marketing Summary"
      >
        <Results
          className={classes.results}
          marketingTreeList={marketingTreeList}
          dataReloadInit={dataReloadInit}
          inprogress={inProgress}
          ledgerList={ledgerList}
          ledgerListCount={ledgerListCount}
        />
      </Page>
    </FormContext>
  );
};

export default MarketingSummary;
