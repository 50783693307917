import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {toastHttpError} from './common';
import {usePagination} from './usePagination';
import moment from "moment";

export const useGetSalesFunnelReport = () => {

  const { step, loss, fromDate, toDate, oid, pid, sid, eid, storeType, setFromDate, setToDate } = useFiltersSearch();
  const [usersList, setUsersList] = useState([]);
  const [usersListCount, setUsersListCount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [dataReload, setDataReload] = useState(false);
  const [ext, setExt] = useState({});

  useEffect(function() {
    !fromDate && setFromDate(moment());
    !toDate && setToDate(moment());
  }, [dataReload, fromDate, toDate, setFromDate, setToDate]);

  const prepareParams = useCallback(({step, loss, fromDate, toDate, oid, pid, sid, eid, storeType}) => {
    const params = {};
    if (oid) { params.oid = oid;}
    if (pid) { params.pid = pid;}
    if (sid) { params.sid = sid;}
    if (eid) { params.eid = eid;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString());}
    if (step) { params.step = step;}
    if (loss) { params.loss = loss;}
    if (storeType) { params.storeType = storeType;}

    return params
  }, []);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((ledgerListPromise) => {
    setUsersListCount(0);
    setUsersList([]);
    setInProgress(true);

    ledgerListPromise.then(response => {

      console.log('response', response.data);
      // setUsersList(response.data);

      if (response?.data?.count) {
        setUsersListCount(response.data.count);

        if (response?.data?.data) {
          setUsersList(response.data.data);
        }
        if (response?.data?.ext) {
          setExt(response.data.ext);
        }
      } else {
        setUsersListCount(0);
        setUsersList([]);
        setExt({});
      }

      setInProgress(false);
    }).catch(response => {
      setInProgress(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      setUsersListCount(0);
      setUsersList([]);
      toastHttpError(response);
    });
  }, []);

  const {page, limit} = usePagination();

  useEffect(function() {

    if (fromDate && toDate) {

      handleResponse(axios().get('/reports/sales-funnel-users', {
        params: {
          page,
          limit,
          ...prepareParams({ step, loss, fromDate, toDate, oid, pid, sid, eid, storeType })
        }
      }));

    } else {
      setUsersListCount(0);
      setUsersList([]);
      setExt({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, step, loss, fromDate, toDate, oid, pid, sid, eid, storeType, handleResponse, prepareParams, /*setUsersListCount, setUsersList, setExt,*/ dataReload]);

  return {
    usersList,
    usersListCount,
    ext,
    inProgress,
    dataReloadInit,
  };
};
