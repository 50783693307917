import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell, TableHead,
  TableRow,
  Typography,
  CardMedia,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {CustomSearch} from '../CustomSearch';
import { Qty } from 'components/Quantity';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  tableItem: {
    verticalAlign: 'middle',
  },
  media: {
    backgroundSize: 'contain',
    width: '100px',
    paddingTop: '56.25%', // 16:9
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  subtotal: {
    margin: 8,
    padding: 10,
    color: '#ffffff',
    background: '#404247',
    borderRadius: 4,
    float: 'right !important',
  },
  total : {
    width: '70px'
  }
}));

export const Items = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { products, subtotal, store_type } = useSelector(({ createOrder }) => createOrder);

  const handleRemoveProduct = useCallback((sku) => {
    dispatch({
      type: 'CREATE_ORDER_DELETE_PRODUCTS',
      payload: sku,
    });
  }, [dispatch]);

  const handleStoreType = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_STORE_TYPE',
      payload: event.target.value,
    });
  }, [dispatch]);

  return (
    <Grid
      className={classes.container}
      container
      spacing={3}
    >
      <Grid
        item
        xs={8}
      >
        <Grid
          item
          md={12}
          xl={12}
          xs={12}
        >
          <Typography variant="h3">Add products</Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography
                    name="title_search"
                    variant="subtitle2"
                  >
                    Store type
                  </Typography>
                </TableCell>
                <TableCell>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    onChange={handleStoreType}
                    row
                    value={store_type || ''}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      disabled
                      label="Electronics"
                      value="electronics"
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Furniture"
                      value="furniture"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    name="title_search"
                    variant="subtitle2"
                  >
                    Search
                  </Typography>
                </TableCell>
                <TableCell>
                  <CustomSearch products />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Grid
          item
          md={12}
          xl={12}
          xs={12}
        >
          {products.length > 0 && <Table stickyHeader>
            <TableHead>
              <TableRow >
                <TableCell className={classes.tableCell}>Products</TableCell>
                <TableCell className={classes.tableCell}>Description</TableCell>
                <TableCell className={classes.tableCell}>Quantity</TableCell>
                <TableCell className={classes.tableCell}>Price</TableCell>
                <TableCell className={classes.tableCell}>Total</TableCell>
                <TableCell className={classes.tableCell}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody  className={classes.tableItem}>
              {products.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <CardMedia
                      className={classes.media}
                      image={(product?.image || product?.imageUrls?.[0] || '/images/no_image_product.png')}
                      title={(product?.name || '')}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">{product?.name}</Typography>
                    <Typography variant="body2">{product?.sku}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <Qty max={product?.stock} sku={product?.sku} value={product?.quantity}/>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{product?.base_cost_price || product?.calculatedPrice}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.total} variant="body1">{product?.total_inc_tax}</Typography>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => handleRemoveProduct(product?.sku)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>}
          {!!subtotal && <div className={classes.subtotal}>{`Subtotal: $${parseFloat(subtotal).toFixed(2)}`}</div>}
        </Grid>
      </Grid>
    </Grid>
  )
}
