import { Button, Card, CardContent, CardHeader, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { getToastHttpError } from 'utils';
import moment from 'moment';
import clsx from 'clsx';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useFormContext } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import HistoryGigaFilter from '../HistoryGigaFilter';
import axios_v2_public from 'utils/axios_v2_public';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    border: 'none'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  padding5: {
    padding: 0
  },
  center: {
    marginTop: theme.spacing(1)
  },
  firstCell: {
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  tableCell: {
    width: '40%',
    textAlign: 'center',
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  tableCellAlignLeft: {
    width: '40%',
    textAlign: 'left',
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  spinnerRoot: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
}));

const HistoryGigaRow = props => {
  const { title, values } = props;
  const classes = useStyles();

  const [oldValue, setOldValue] = useState('-');
  const [newValue, setNewValue] = useState('-');

  function genValue(value, title) {
    let valueMasquerade;
    if (value) {
      if (title === 'Item Update Date') {
        valueMasquerade = moment(value).format('DD MMM YYYY  h:mm a');
      } else {
        valueMasquerade = value.toString();
      }
    }
    return valueMasquerade;
  }

  useEffect(() => {
    switch (values.length) {
      case 1:
        setNewValue(genValue(values[0]?.toString(), title) || '-');
        return;
      case 2:
        setOldValue(genValue(values[0]?.toString(), title));
        setNewValue(genValue(values[1]?.toString(), title));
        return;
      case 3:
        setOldValue(genValue(`${values[0]}`, title) || '-');
        return;
      default:
        return;
    }
  }, [values, title]);

  return (<>{oldValue !== newValue && <TableRow>
    <TableCell className={classes.firstCell}>
      <Typography variant="h6">{title}</Typography>
    </TableCell>
    <TableCell className={classes.tableCell}>
      <Typography variant="subtitle2">
        {oldValue}
      </Typography>
    </TableCell>
    <TableCell className={classes.tableCell}>
      <Typography variant="subtitle2">
        {newValue}
      </Typography>
    </TableCell>
  </TableRow>} </>);
};

const HistoryGigaComponent = props => {
  const { history, id } = props;
  const classes = useStyles();

  const [isShowComponent, setIsShowComponent] = useState(false);

  useEffect(() => {
    const isExistInfo = history?.diff?.regularPrice || history?.diff?.ddAvailable || history?.diff?.active || history?.diff?.ddCategory || history?.diff?.discountedPrice || history?.diff?.exclusivePrice || history?.diff?.salePrice || history?.diff?.skuAvailable || history?.diff?.customPrice || history?.diff?.promotionTo || history?.diff?.promotionFrom
    setIsShowComponent(isExistInfo);
  }, [history]);

  return (
    <React.Fragment key={'main' + id}>
      {isShowComponent && <React.Fragment key={id}>
        <TableRow className={classes.tableSelected}>
          <TableCell className={classes.tableCellAlignLeft} colSpan={3}>
            <Typography variant="h6"> {moment(history.createdAt).format('DD MMM YYYY  h:mm a')}</Typography>
          </TableCell>
        </TableRow>

        {history?.diff?.ddAvailable && <HistoryGigaRow title="DD Available" values={history?.diff?.ddAvailable} />}

        {history?.diff?.active && <HistoryGigaRow title="Active" values={history?.diff?.active} />}

        {history?.diff?.ddCategory && <HistoryGigaRow title="DD Category" values={history?.diff?.ddCategory} />}

        {history?.diff?.regularPrice && <HistoryGigaRow title="Regular Price" values={history?.diff?.regularPrice} />}

        {history?.diff?.discountedPrice && <HistoryGigaRow title="Discounted Price" values={history?.diff?.discountedPrice} />}

        {history?.diff?.exclusivePrice && <HistoryGigaRow title="Exclusive Price" values={history?.diff?.exclusivePrice} />}

        {history?.diff?.salePrice && <HistoryGigaRow title="Sale Price" values={history?.diff?.salePrice} />}

        {history?.diff?.promotionTo && <HistoryGigaRow title="Promotion To" values={history?.diff?.promotionTo} />}

        {history?.diff?.promotionFrom && <HistoryGigaRow title="Promotion From" values={history?.diff?.promotionFrom} />}

        {history?.diff?.customPrice && <HistoryGigaRow title="Custom Price" values={history?.diff?.customPrice} />}

        {history?.diff?.skuAvailable && <HistoryGigaRow title="SKU Available" values={history?.diff?.skuAvailable} />}

      </React.Fragment>}
    </React.Fragment>
  );
};

const ProductGigaHistory = props => {
  const { productId/*, ...rest*/ } = props;

  const classes = useStyles();

  const [history, setHistory] = useState([]);
  const [isProgressIn, setProgressIn] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  const { register, watch } = useFormContext();

  register({ name: 'regularPrice', type: 'custom' });
  register({ name: 'ddAvailable', type: 'custom' });
  register({ name: 'active', type: 'custom' });
  register({ name: 'ddCategory', type: 'custom' });
  register({ name: 'discountedPrice', type: 'custom' });
  register({ name: 'exclusivePrice', type: 'custom' });
  register({ name: 'salePrice', type: 'custom' });
  register({ name: 'skuAvailable', type: 'custom' });
  register({ name: 'customPrice', type: 'custom' });
  register({ name: 'promotionTo', type: 'custom' });
  register({ name: 'promotionFrom', type: 'custom' });


  const {
    promotionTo,
    promotionFrom,
    regularPrice,
    ddAvailable,
    active,
    ddCategory,
    discountedPrice,
    exclusivePrice,
    salePrice,
    skuAvailable,
    customPrice
  } = watch();

  const handleFiltersOpen = () => {
    setOpenFilters(true);
  };

  const handleFiltersClose = () => {
    setOpenFilters(false);
  };

  const getProductGigaHistory = useCallback(() => {
    setProgressIn(true);
    const params = {};
    if (active === true) params.active = true
    if (regularPrice === true) params.regularPrice = true;
    if (ddAvailable === true) params.ddAvailable = true;
    if (ddCategory === true) params.ddCategory = true
    if (discountedPrice === true) params.discountedPrice = true
    if (exclusivePrice === true) params.exclusivePrice = true
    if (salePrice === true) params.salePrice = true
    if (skuAvailable === true) params.skuAvailable = true
    if (customPrice === true) params.customPrice = true
    if (promotionFrom === true) params.promotionFrom = true
    if (promotionTo === true) params.promotionTo = true

    axios_v2_public().get(`products/${productId}/diffs`, {
      params: params
    }).then(response => {
      setHistory(response.data);
      handleFiltersClose();
      setProgressIn(false);
    }).catch(response => {
      setHistory([]);
      setProgressIn(false);
      getToastHttpError(response);
      if (response.response?.status === 404) {
        history.push('/not-found');
      }
    });
  }, [
    promotionFrom,
    promotionTo,
    regularPrice,
    ddAvailable,
    active,
    ddCategory,
    discountedPrice,
    exclusivePrice,
    salePrice,
    skuAvailable,
    customPrice,
    productId,
    history,
  ]);

  useEffect(() => {
    getProductGigaHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return (<Card>
    <CardHeader
      name="header_customer_history"
      title={<div className={clsx([classes.container, classes.padding5])}>
        Product Data Update History
      </div>}
      action={
        <div className={clsx([classes.center, classes.padding5])}>
          <Button
            className={classes.filterButton}
            color="primary"
            onClick={handleFiltersOpen}
            size="small"
            variant="outlined"
          >
            <FilterListIcon className={classes.filterIcon} /> Show filters
          </Button>
        </div>
      }
    />
    <Divider />
    <CardContent className={classes.content}>
      {history.length > 0 && !isProgressIn &&
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.firstCell}> Modified Fields </TableCell>
              <TableCell className={classes.tableCell}> Old Value </TableCell>
              <TableCell className={classes.tableCell}> New Value </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((history, id) => <HistoryGigaComponent key={id} history={history} id={id} />)}
          </TableBody>
        </Table>
      }
      {isProgressIn && <div className={classes.spinnerRoot}>
        <CircularProgress size={60} />
      </div>}
      {history.length === 0 && <div className={classes.spinnerRoot}>
        <Typography variant={'subtitle2'}>History is empty</Typography>
      </div>}
    </CardContent>
    {openFilters && <HistoryGigaFilter
      onClose={handleFiltersClose}
      onConfirm={getProductGigaHistory}
      open={openFilters}
    />}
  </Card>);
};

ProductGigaHistory.propTypes = {
  customerId: PropTypes.string
};

export default ProductGigaHistory;
