import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  colors,
  Divider,
  TextField,
  Radio,
  Grid,
  Typography, TableHead, TableBody, TableRow, TableCell, Table, CardActions, Button, Switch
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useHistory} from 'react-router-dom';

import axios from 'utils/axios.js';
import { getToastHttpError } from 'utils/common';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import * as PropTypes from 'prop-types';
import { GridContainer, GridItem } from 'common';
import useForm from 'react-hook-form';
import _isEmpty from 'lodash/isEmpty';
import Avatar from '@material-ui/core/Avatar';
import FindAttributes from './components/FindAttributes/FindAttributes';
import UpdateAttributes from './components/UpdateAttributes/UpdateAttributes';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
  root: {},
  reprocessButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    },
  },
  deliveryOptionsButton: {
    color: theme.palette.white,
    backgroundColor: colors.blue[600],
    '&:hover': {
      backgroundColor: colors.lightBlue[900]
    }
  },
  previousStatusButton: {
    color: theme.palette.white,
    backgroundColor: colors.deepOrange[600],
    '&:hover': {
      backgroundColor: colors.deepOrange[900]
    }
  },
  updateButton: {
    color: theme.palette.white,
    backgroundColor:  colors.blue[600],
    '&:hover': {
      backgroundColor: colors.blue[900]
    }
  },
  select_input: {
    height: 56
  },
  select_input2: {
    height: 85
  },
  action_label: {
    width: 200
  },
  content: {
    padding: 0
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  option: {
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },
  selectedOption: {
    backgroundColor: colors.grey[50]
  },
  optionRadio: {
    margin: -10
  },
  optionDetails: {
    marginLeft: theme.spacing(2)
  },
  deliveryRoot: {
    padding: theme.spacing(1),
    marginBottom: 0
  },
  icon_label: {
    width: 70
  },
  deliverySpinner: {
    marginLeft: theme.spacing(2)
  },
  flowStep: {
    backgroundColor: colors.green[500],
    marginRight: theme.spacing(2),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  stButton: {
    marginLeft: 12,
  },
  deliveryToColumn: {
    minWidth: 150
  },
  tableVerticalAlign: {
    verticalAlign: 'top',
    '& > .MuiSvgIcon-root': {
      marginTop: 16,
    },
    '& > .MuiTypography-root': {
      paddingTop: 18,
    }
  },
  optionSwitch: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));


const options = [
  {
    value: 'findAttributes',
    title: 'Update by attribute ',
    description: 'Order attributes will be updated'
  },
  {
    value: 'delivery', //
    title: 'Manage delivery address',
    description: 'Manager can set customer home address as order delivery address. Please verify delivery address bellow, modify if required then select delivery options and click \'Re-process order with new delivery address\' button.'
  }
];

// const leaseProviderOptions = [
//   {
//     value: 'acima',
//     title: 'Acima',
//   },
//   {
//     value: 'westcreek',
//     title: 'Westcreek',
//   },
// ];

// const leaseProviders = {
//   acima: 'Acima',
//   westcreek: 'Westcreek',
// };

const mapDeliveryTo = [
  'Home',
  'Store'
];

export const OrderReprocess = props => {
  const { order: {
    products_data,
    billing_address,
    id,
    lease_provider,
    checkout_token,
    lease_id,
    lease_number,
    application_id,
    transaction_id,
    ext_id,
    ext_id_history,
    shipping_address,
    ...rest
  }} = props;

  const isBestBuy = _isEmpty(rest?.supplier) || rest?.supplier === 'bestbuy';

  const history = useHistory();

  const classes = useStyles();

  const {
    register,
    errors,
    watch//,
    // formState: { dirty, isValid }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      first_name: shipping_address?.firstname || billing_address?.first_name || '',
      last_name: shipping_address?.lastname || billing_address?.last_name || '',
      phone: shipping_address?.phonenumber || billing_address?.phone || '',
      street_1: shipping_address?.address1 || billing_address?.street_1 || '',
      street_2: shipping_address?.address2 || billing_address?.street_2 || '',
      city: shipping_address?.city || billing_address?.city || '',
      state: shipping_address?.state || billing_address?.state || '',
      zip: shipping_address?.postalcode || billing_address?.zip || ''
    }
  });

  const {first_name, last_name, phone, street_1, street_2, city, state, zip} = watch();

  const [mode, setMode] = useState('findAttributes');
  const [listAddressDelivery, setListAddressDelivery] = useState({});
  const [choiceDelivery, setChoiceDelivery] = useState([]);

  const [arrayStoresForSku, setArrayStoresForSku] = useState({});
  const [isProgressArrayStoresForSku, setIsProgressArrayStoresForSku] = useState({});

  const [currentSkuForGetStores, setCurrentSkuForGetStores] = useState();

  // const [labelLeaseProvider, setLabelLeaseProvider] = useState(lease_provider);
  //
  // useEffect(  () => {
  //   const itemLeaseProvider = LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider);
  //   if (itemLeaseProvider) {
  //     setLabelLeaseProvider(itemLeaseProvider.label);
  //   }
  // }, [setLabelLeaseProvider, lease_provider]);


  const storesForSku = () => {
    setIsProgressArrayStoresForSku({...isProgressArrayStoresForSku, ...{[currentSkuForGetStores]: true}});

    axios().get('/stores/product', {
      params: {
        zip: zip,
        sku: currentSkuForGetStores
      }
    }).then((response) => {
      setArrayStoresForSku({...arrayStoresForSku, ...{[currentSkuForGetStores]: response.data?.data || []}});

      setChoiceDelivery(choiceDelivery.map(object => {

        if(object.sku === +currentSkuForGetStores) {
          return {
            ...object,
            storeId: response.data?.data[0]?.id.toString() || ''
          }
        }
        else return object;
      }));

      setIsProgressArrayStoresForSku({...isProgressArrayStoresForSku, ...{[currentSkuForGetStores]: false}});
    }).catch((error) => {
      setArrayStoresForSku({...arrayStoresForSku, ...{[currentSkuForGetStores]: []} });
      setIsProgressArrayStoresForSku({...isProgressArrayStoresForSku, ...{[currentSkuForGetStores]: false}});
      getToastHttpError(error || 'Something Went Wrong');
    });
  };

  const handleDeliveryChange = event => {
    event && event.preventDefault();
    setChoiceDelivery(choiceDelivery.map(object => {

      if(object.sku === +event.target.name) {
        return {
          ...object,
          key: event.target.value,
          selected_shipping_option: listAddressDelivery[object.sku]?.find(shipping_option => shipping_option.key===event.target.value)
        }
      }
      else return object;
    }));
  };

  const handleDeliveryTo = event => {
    event && event.preventDefault();
    setChoiceDelivery(choiceDelivery.map(object => {

      if(object.sku === +event.target.name) {
        setCurrentSkuForGetStores(event.target.name);
        return {
          ...object,
          deliveryTo: event.target.value
        }
      }
      else return object;
    }));
  };

  const handleStoreIdChange = event => {
    event && event.preventDefault();
    setChoiceDelivery(choiceDelivery.map(object => {

      if(object.sku === +event.target.name) {
        return {
          ...object,
          storeId: event.target.value
        }
      }
      else return object;
    }));
  };

  useEffect( () => {
    // console.log('useEffect  currentSkuForGetStores=' + currentSkuForGetStores );
    if (!!currentSkuForGetStores && (choiceDelivery.find( option => option.sku === +currentSkuForGetStores )?.deliveryTo === mapDeliveryTo[1])) { //Store
      // console.log('useEffect !!!!! sku=' + currentSkuForGetStores + ' zip=' + zip);
      storesForSku();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSkuForGetStores/*, storesForSku, choiceDelivery*/]);

  const [advancedMode, setAdvancedMode] = useState(false);

  const handleAdvancedModeChange = event => {
    event.persist();
    setAdvancedMode(event.target.checked)
  };

  // -------- reprocess with new delivery address

  const reprocessOrderDelivery = useCallback(() => {
    let data = {};

    if (mode) data.mode = mode;

    if (first_name) data.firstname = first_name;
    if (last_name) data.lastname = last_name.trim();
    if (phone) data.phonenumber = phone.trim();
    if (street_1) data.address1 = street_1.trim();
    if (street_2) data.address2 = street_2.trim();
    if (city) data.city = city.trim();
    if (state) data.state = state.trim();
    if (zip) data.postalcode = zip.trim();
    data.country = billing_address.country || 'United States';
    data.country_iso2 = billing_address.country_iso2 || 'US';

    const shipping_options = choiceDelivery.map(option => {
      if (option.deliveryTo === mapDeliveryTo[0]) {        // Home
        return { sku: option.sku, key: option.key || '', selected_shipping_option: option.selected_shipping_option};
      } else if (option.deliveryTo === mapDeliveryTo[1]) { // Store
        return { sku: option.sku, store_id: option.storeId || ''};
      }
      return option;
    }
    );

    data.shipping_options = shipping_options;

    return axios().put(`orders/${id}/reprocess`, data).then(() => {
      return `Order ID #${id} processed successfully`;
    }).catch(response => {
      getToastHttpError(response || 'Something Went Wrong');
      return Promise.reject('Request Failed');
    });
  }, [id, first_name, last_name, phone, street_1, street_2, city, state, zip, mode, choiceDelivery, billing_address.country, billing_address.country_iso2]);

  const handleButtonDelivery = () => {
    setOpenDeliveryConfirmation(false);
    reprocessOrderDelivery().then((success_message) => {
      toast.success(success_message);
      history.push('/orders/' + id);
    });
  };

  const [openDeliveryConfirmation, setOpenDeliveryConfirmation] = useState(false);

  const handleClickOpenDeliveryConfirmation = () => {
    setOpenDeliveryConfirmation(true);
  };

  const handleCloseDeliveryConfirmation = () => {
    setOpenDeliveryConfirmation(false);
  };
  // --------

  const handleChange = (event, option) => {
    setMode(option.value);
  };

  //--> ------------ get delivery options
  // const [listAddressDelivery, setListAddressDelivery] = useState({});

  const [expandedList, setExpandedList] = useState(false);

  const [inProcessAddressDeliveryOptions, setInProcessAddressDeliveryOptions] = useState(false);

  const fetchAddressDeliveryOptions = useCallback(() => {
    setInProcessAddressDeliveryOptions(true);

    let data = {};
    data.params = {};

    if (street_1) data.params.address1 = street_1.trim();
    if (street_2) data.params.address2 = street_2.trim();
    if (city) data.params.city = city.trim();
    if (state) data.params.state = state.trim();
    if (zip) data.params.postalcode = zip.trim();
    data.params.country = billing_address.country || 'United States';
    data.params.country_iso2 = billing_address.country_iso2 || 'US';

    return axios().get(`orders/${id}/address_delivery_options`, data).then((response) => {
      setInProcessAddressDeliveryOptions(false);
      // console.log(response.data);
      return response.data;
    }).catch(response => {
      setInProcessAddressDeliveryOptions(false);
      getToastHttpError(response || 'Something Went Wrong');
      return Promise.reject('Request Failed');
    });
  }, [id, street_1, street_2, city, state, zip, billing_address.country, billing_address.country_iso2]);

  const [addressFieldsEditMode, setAddressFieldsEditMode] = useState(false);

  const enableAddressFieldsEdit = useCallback(() => {
    setAddressFieldsEditMode(true);
  },[setAddressFieldsEditMode]);

  const  getAddressDeliveryOptions = useCallback(() => {
    if (!expandedList) {
      fetchAddressDeliveryOptions().then((data) => {

        setListAddressDelivery(data);

        let tempChoiceDelivery = [];

        for (const sku in data) {

          const options_by_sku = data[sku];

          let key = '';
          let selected_shipping_option = undefined;

          const free_option = options_by_sku.find(option => option.price==='0.00');

          if (free_option) {
            key = free_option.key;
            selected_shipping_option = free_option;
          }

          tempChoiceDelivery.push({
            sku: +sku,
            key: key,
            selected_shipping_option: selected_shipping_option,
            deliveryTo: mapDeliveryTo[0],
            storeId: ''
          });
        }

        setChoiceDelivery(tempChoiceDelivery);

        setExpandedList(true);
      });
    } else {
      setListAddressDelivery([]);
      setChoiceDelivery([]);
      setExpandedList(false);
    }
  }, [expandedList, fetchAddressDeliveryOptions]);

  const handleAddressChange = useCallback(() => {
    setListAddressDelivery([]);
    setChoiceDelivery([]);
    setExpandedList(false);
  }, []);

  const isNotDeliveryOptions = useCallback(() => {
    if (!isBestBuy) return false
    return !!(choiceDelivery?.find((option =>
      ( option?.deliveryTo === mapDeliveryTo[0] && option?.key === '' ) ||
        ( option?.deliveryTo === mapDeliveryTo[1] && option?.storeId === '' )
    )))
  }, [choiceDelivery, isBestBuy]);

  // <---------------------
  // console.log('choiceDelivery = ');
  // console.log(choiceDelivery);
  // console.log('arrayStoresForSku=');
  // console.log(arrayStoresForSku);
  return (
    <Card
      className={clsx(classes.root)}
    >
      <CardHeader title="Edit Order Attributes" />
      <Divider />
      <CardContent className={classes.content}>
        {options.map(option => (
          <div
            className={clsx(classes.option, {
              [classes.selectedOption]: mode === option.value
            })}
            key={option.value}
          >
            <Radio
              checked={mode === option.value}
              className={classes.optionRadio}
              color="primary"
              onClick={event => handleChange(event, option)}
            />
            <div className={classes.optionDetails}>
              <Typography
                gutterBottom
                variant="h5"
              >
                {option.title}
              </Typography>
              <Typography variant="body1">{option.description}</Typography>
            </div>
          </div>
        ))}
        {mode === 'delivery' && <div /*style={{ padding: 20 }}*/>
          <Grid
            alignItems="center"
            container
            // justify="center"
            direction="row"
            style={{ padding: 20 }}
          >
            <Avatar className={classes.flowStep}>1</Avatar>
            <Typography variant="h6">
              New delivery address
            </Typography>
          </Grid>
          <GridContainer className={classes.deliveryRoot}>
            <GridItem md={12}>
              <Button
                className={classes.stButton}
                color="primary"
                disabled={addressFieldsEditMode}
                onClick={enableAddressFieldsEdit}
                variant="contained"
              >
                Edit
              </Button>
            </GridItem>
            <GridItem>
              <TextField
                className={classes.textField}
                disabled={!addressFieldsEditMode}
                error={!!errors.first_name}
                fullWidth
                helperText={
                  errors.first_name && errors.first_name.message
                }
                inputRef={register({
                  required: 'First name is required',
                  pattern: {
                    value: /^(?![ .\-']*$)[a-zA-Z .\-']+$/,
                    message: 'First Name must contain English letters only'
                  }
                })}
                label={'First name'}
                name="first_name"
                onChange={handleAddressChange}
                required
                value={first_name}
                variant="outlined"
              />
            </GridItem>
            <GridItem>
              <TextField
                disabled={!addressFieldsEditMode}
                error={!!errors.last_name}
                fullWidth
                helperText={
                  errors.last_name?.message
                }
                inputRef={register({
                  required: 'Last name is required',
                  pattern: {
                    value: /^(?![ .\-']*$)[a-zA-Z .\-']+$/,
                    message: 'Last Name must contain English letters only'
                  }
                })}
                label={'Last name'}
                name="last_name"
                onChange={handleAddressChange}
                required
                value={last_name}
                variant="outlined"
              />
            </GridItem>
            <GridItem>
              <TextField
                disabled={!addressFieldsEditMode}
                error={!!errors.street_1}
                fullWidth
                helperText={
                  errors.street_1?.message
                }
                inputRef={register({
                  required: 'Street is required'
                })}
                label={'Street 1'}
                name="street_1"
                onChange={handleAddressChange}
                required
                value={street_1}
                variant="outlined"
              />
            </GridItem>
            <GridItem>
              <TextField
                disabled={!addressFieldsEditMode}
                fullWidth
                inputRef={register({})}
                label={'Street 2'}
                name="street_2"
                onChange={handleAddressChange}
                value={street_2}
                variant="outlined"
              />
            </GridItem>
            <GridItem>
              <TextField
                disabled={!addressFieldsEditMode}
                error={!!errors.city}
                fullWidth
                helperText={
                  errors.city?.message
                }
                inputRef={register({
                  required: 'City is required'
                })}
                label={'City'}
                name="city"
                onChange={handleAddressChange}
                required
                value={city}
                variant="outlined"
              />
            </GridItem>
            <GridItem>
              <TextField
                disabled={!addressFieldsEditMode}
                error={!!errors.state}
                fullWidth
                helperText={
                  errors.state?.message
                }
                inputRef={register({
                  required: 'State is required'
                })}
                label={'State'}
                name="state"
                onChange={handleAddressChange}
                required
                value={state}
                variant="outlined"
              />
            </GridItem>
            <GridItem>
              <TextField
                disabled={!addressFieldsEditMode}
                error={!!errors.zip}
                fullWidth
                helperText={
                  errors.zip?.message
                }
                inputRef={register({
                  required: 'Zip is required',
                  pattern: {
                    value: /^\d{5}(?:[-\s]\d{4})?$/,
                    message: 'Zip Code should be in either XXXXX or XXXXX-XXXX format'
                  }
                })}
                label={'Zip'}
                name="zip"
                onChange={handleAddressChange}
                required
                // type="number"
                value={zip}
                variant="outlined"
              />
            </GridItem>
            <GridItem>
              <TextField
                disabled={!addressFieldsEditMode}
                error={!!errors.phone}
                fullWidth
                helperText={
                  errors.phone?.message
                }
                inputRef={register({
                  required: 'Phone is required',
                  pattern: {
                    value: /^[0-9, \x28, \x29, \x2d, \x2b, \x20]*$/,
                    message: 'Phone must not contain only characters: + - (), 0-9'
                  }
                })}
                label={'Phone'}
                name="phone"
                onChange={handleAddressChange}
                required
                value={phone}
                variant="outlined"
              />
            </GridItem>
          </GridContainer>
          <Divider />
          {isBestBuy && <Grid
            alignItems="center"
            className={classes.deliveryRoot}
            container
            direction="row"
            style={{ padding: 20 }}
          >
            <Avatar className={classes.flowStep}>2</Avatar>
            <Typography variant="h6">
              Delivery options
            </Typography>
          </Grid>}
          {isBestBuy && <GridContainer className={classes.deliveryRoot}>
            <GridItem md={12}>
              <Button
                className={classes.stButton}
                color="primary"
                disabled={(expandedList || !_isEmpty(errors))}
                onClick={getAddressDeliveryOptions}
                variant="contained"
              >
                Set up delivery options
              </Button>
              {inProcessAddressDeliveryOptions && <CircularProgress
                className={classes.deliverySpinner}
                size={20}
              /> }
            </GridItem>
          </GridContainer>}

          { isBestBuy && (!_isEmpty(listAddressDelivery) /*&& dirty && !isValid*/) &&  <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Sku</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell className={classes.deliveryToColumn}>Delivery to</TableCell>
                <TableCell className={classes.icon_label} />
                <TableCell >Delivery options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(listAddressDelivery).map( sku => (
                <TableRow
                  key={sku}
                >
                  <TableCell >
                    <Typography variant="subtitle2">
                      {sku}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.action_label}>
                    <Typography variant="subtitle2">
                      { products_data.find(product => product.sku?.toString() === sku)?.name || ''}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableVerticalAlign}
                  >
                    <TextField
                      disabled={choiceDelivery.some((item) => isProgressArrayStoresForSku[item.sku])}
                      fullWidth
                      label = ""
                      name={sku}
                      onChange={handleDeliveryTo}
                      // eslint-disable-next-line react/jsx-sort-props
                      select
                      SelectProps={{
                        native: true
                      }}
                      value={choiceDelivery.find( option => option.sku === +sku )?.deliveryTo}
                      variant="outlined"
                    >
                      { mapDeliveryTo.map((stringDelivery,i) => (
                        <option key={i} >
                          {stringDelivery}
                        </option>
                      ))}
                    </TextField>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableVerticalAlign}
                  >
                    {((choiceDelivery.find( option => option.sku === +sku )?.deliveryTo === mapDeliveryTo[0] && choiceDelivery.find( option => option.sku === +sku )?.key === '')
                      || (!isProgressArrayStoresForSku[sku] && choiceDelivery.find( option => option.sku === +sku )?.deliveryTo === mapDeliveryTo[1] && choiceDelivery.find( option => option.sku === +sku )?.storeId === '')) ?
                      <ErrorOutlinedIcon style={{ color: colors.red[600] }} /> : ''}
                  </TableCell>
                  <TableCell className={clsx(classes.select_input2, classes.tableVerticalAlign)}>
                    { (choiceDelivery.find( option => option.sku === +sku )?.deliveryTo === mapDeliveryTo[0]) &&
                      (listAddressDelivery[sku].length ?  <TextField
                        error ={(choiceDelivery.find( option => option.sku === +sku )?.key === '')}
                        fullWidth
                        helperText={(choiceDelivery.find( option => option.sku === +sku )?.key === '') &&
                         'Please select a delivery option'}
                        label = ""
                        name={sku}
                        onChange={handleDeliveryChange}
                        // eslint-disable-next-line react/jsx-sort-props
                        select
                        SelectProps={{
                          native: true
                        }}
                        value={choiceDelivery.find( option => option.sku === +sku )?.key}
                        variant="outlined"
                      >
                        <option value="" />
                        {listAddressDelivery[sku].map(typeDelivery => (
                          <option
                            key={typeDelivery.key || 'blank'}
                            value={typeDelivery.key}
                          >
                            {typeDelivery.key ? `${typeDelivery._} (delivery day ${typeDelivery['expected-delivery-date']}) $${typeDelivery.price}` : ''}
                          </option>
                        ))}
                      </TextField>
                        : <Typography>
                            No delivery options for zip
                        </Typography>
                      )}

                    { (choiceDelivery.find( option => option.sku === +sku )?.deliveryTo === mapDeliveryTo[1]) &&
                    (isProgressArrayStoresForSku[sku] ? <div className={classes.select_input}><CircularProgress size={16} /></div> : (
                      !_isEmpty(arrayStoresForSku[sku]) ? <TextField
                        error ={(choiceDelivery.find( option => option.sku === +sku )?.storeId === '')}
                        fullWidth
                        helperText={(choiceDelivery.find( option => option.sku === +sku )?.storeId === '') &&
                          'Please select store ID'}
                        label = ""
                        name={sku}
                        onChange={handleStoreIdChange}
                        // eslint-disable-next-line react/jsx-sort-props
                        select
                        SelectProps={{
                          native: true
                        }}
                        value={choiceDelivery.find( option => option.sku === +sku )?.storeId}
                        variant="outlined"
                      >
                        <option value="" />
                        {arrayStoresForSku[sku].map(store => (
                          <option
                            key={store.id || 'blank'}
                            value={store.id}
                          >
                            {store.id ? `ID #${store.id} ${store.longName} ${store.address} ${store.city} ${store.region} ${store.postalCode} Distance: ${store.distance} mi` : ''}
                          </option>
                        ))}
                      </TextField>
                        : <Typography>
                          No stores found for zip
                        </Typography>
                    ))}
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>

          }
        </div>
        }
        {mode === 'findAttributes' &&
          <div>
            <FormControlLabel
              className={classes.optionSwitch}
              control={
                <Switch
                  checked={advancedMode}
                  color="secondary"
                  edge="start"
                  name="advanced_mode"
                  onChange={handleAdvancedModeChange}
                />
              }
              label="Advanced mode"
            />
            {!advancedMode  && <FindAttributes order_id={id}/>}
            {advancedMode  &&
            <UpdateAttributes
              application_id = {application_id}
              checkout_token = {checkout_token}
              ext_id= {ext_id}
              ext_id_history = {ext_id_history}
              lease_id = {lease_id}
              lease_number = {lease_number}
              lease_provider = {lease_provider}
              order_id={id}
              transaction_id={transaction_id}
            />
            }
          </div>
        }
      </CardContent>
      <Divider />
      <CardActions style={{ padding: mode === 'delivery' ? 0 : 20 }}>
        { (mode === 'delivery') && <div style={{ padding: -20 }}>
          <Divider />
          <Grid
            alignItems="center"
            className={classes.deliveryRoot}
            container
            direction="row"
            style={{ padding: 20 }}
          >
            <Avatar className={classes.flowStep}>3</Avatar>
            <Typography variant="h6">
              Actions
            </Typography>
          </Grid>
          <GridContainer className={classes.deliveryRoot}>
            <GridItem md={12}>
              <Button
                className={clsx(classes.reprocessButton,classes.stButton)}
                color="primary"
                disabled={!_isEmpty(errors) || (isBestBuy && !expandedList) || isNotDeliveryOptions()}
                onClick={handleClickOpenDeliveryConfirmation}
                variant="contained"
              >
                Re-process order with new delivery address
              </Button>
            </GridItem>
          </GridContainer>
        </div>
        }
      </CardActions>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseDeliveryConfirmation}
        open={openDeliveryConfirmation}
      >
        <DialogTitle id="alert-dialog-title">Set new delivery address to Order #{id}?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            After confirmation, a new order will be created with the entered delivery address on the Doradodidgital side.
            Do not forget to delete the old order on {isBestBuy ? 'BestBuy' : 'Giga'} side.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCloseDeliveryConfirmation}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={handleButtonDelivery}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

OrderReprocess.propTypes = {
  currentStores: PropTypes.array,
  order: PropTypes.shape({
    id: PropTypes.number,
    lease_id: PropTypes.string,
    products_data: PropTypes.array,
    checkout_token: PropTypes.string,
    billing_address: PropTypes.object,
    lease_number: PropTypes.string,
    // ext_id,
    // previous_state,
    ext_id_history: PropTypes.array,
    shipping_address: PropTypes.object
  })
};
