import { Card, CardContent, CardHeader, Divider, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import axios from 'utils/axios';
import { getToastHttpError } from 'utils';
import moment from 'moment';
import clsx from 'clsx';
import HistoryFilter from '../HistoryFilter';
// import FilterListIcon from '@material-ui/icons/FilterList';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    border: 'none'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  padding5: {
    padding: 0
  },
  center: {
    marginTop: theme.spacing(1)
  },
  firstCell: {
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  tableCell: {
    // width: '40%',
    // textAlign: 'center',
    padding: theme.spacing(1),
    paddingLeft: '16px',
    wordBreak: 'break-word'
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  spinnerRoot: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
  columnTable: {
    width: '30%'
  },
  data: {
    overflowWrap: 'anywhere',
  }
}));


const HistoryComponent = props => {
  const { history, id } = props;
  const classes = useStyles();

  const [isShowComponent, setIsShowComponent] = useState(true);

  useEffect(() => {
    if (/*history?.last_auth_timestamp ||*/ history?.last_auth_through /*|| history?.last_auth_timestamp*/ || history?.last_auth_ip_address || history?.last_auth_country_code) {
      setIsShowComponent(true);
    }
  }, [history]);

  return (
    <React.Fragment key={'main' + id}>
      {isShowComponent && <React.Fragment key={id}>
        <TableRow className={classes.tableSelected}>
          <TableCell
            align="left"
            className={classes.tableCell}
            colSpan={2}
          >
            {/*<Typography variant="h6"> {moment(history.createdAt).format('DD MMM YYYY  h:mm:ss a')}</Typography>*/}
            <Typography variant="h6"> {moment(history.last_auth_timestamp).format('DD MMM YYYY  h:mm:ss a')}</Typography>
          </TableCell>
          {/*<TableCell/>*/}
        </TableRow>

        {history?.last_auth_through && <TableRow>
          <TableCell className={classes.columnTable} >
            <Typography variant="subtitle2">
              Last auth through
            </Typography>
          </TableCell>
          <TableCell>
            <div>{history?.last_auth_through || ''}</div>
          </TableCell>
        </TableRow>}

        {history?.last_auth_through==='email' && <TableRow>
          <TableCell className={classes.columnTable} >
            <Typography variant="subtitle2">
              Email
            </Typography>
          </TableCell>
          <TableCell>
            <div className={classes.data}>{history?.email || ''}</div>
          </TableCell>
        </TableRow>}

        {history?.last_auth_through==='phone' && <TableRow>
          <TableCell className={classes.columnTable} >
            <Typography variant="subtitle2">
              Phone
            </Typography>
          </TableCell>
          <TableCell>
            <div className={classes.data}>{history?.phone || ''}</div>
          </TableCell>
        </TableRow>}

        {history?.last_auth_ip_address && <TableRow>
          <TableCell className={classes.columnTable} >
            <Typography variant="subtitle2">
              IP address
            </Typography>
          </TableCell>
          <TableCell>
            <div>{history?.last_auth_ip_address || ''}</div>
          </TableCell>
        </TableRow>}

        {history?.last_auth_country_code && <TableRow>
          <TableCell className={classes.columnTable} >
            <Typography variant="subtitle2">
              Country code
            </Typography>
          </TableCell>
          <TableCell>
            <div>{history?.last_auth_country_code || ''}</div>
          </TableCell>
        </TableRow>}

        {history?.store_type && <TableRow>
          <TableCell className={classes.columnTable} >
            <Typography variant="subtitle2">
              Store type
            </Typography>
          </TableCell>
          <TableCell>
            <div>{history?.store_type || ''}</div>
          </TableCell>
        </TableRow>}

      </React.Fragment>}
    </React.Fragment>
  );
}

const AuthHistory = props => {
  const { customerId/*, ...rest*/ } = props;

  const classes = useStyles();

  const [history, setHistory] = useState([]);
  const [isProgressIn, setProgressIn] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  // const { register, watch } = useFormContext();

  // register({ name: 'email', type: 'custom' });
  // register({ name: 'first_name', type: 'custom' });
  // register({ name: 'last_name', type: 'custom' });
  // register({ name: 'phone', type: 'custom' });

  // const { email, first_name, last_name, phone } = watch();

  // const handleFiltersOpen = () => {
  //   setOpenFilters(true);
  // };

  const handleFiltersClose = () => {
    setOpenFilters(false);
  };

  const getCustomerHistory = useCallback(() => {
    setProgressIn(true);
    // const params = {};
    // if (email === true) params.email = true;
    // if (first_name === true) params.first_name = true;
    // if (last_name === true) params.last_name = true;
    // if (phone === true) params.phone = true;

    axios().get(`customer/${customerId}/auth_history`, {
      // params: params
    }).then(response => {
      setHistory(response.data);
      handleFiltersClose();
      setProgressIn(false);
    }).catch(response => {
      setHistory([]);
      setProgressIn(false);
      getToastHttpError(response);
      if (response.response?.status === 404) {
        history.push('/not-found');
      }
    });
  }, [/*email, first_name, last_name, phone,*/ customerId, history]);

  useEffect(() => {
    getCustomerHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId/*, getCustomerHistory*/]);

  return (<Card>
    <CardHeader
      action={
        <div className={clsx([classes.center, classes.padding5])}>
          {/*<Button*/}
          {/*  className={classes.filterButton}*/}
          {/*  color="primary"*/}
          {/*  onClick={handleFiltersOpen}*/}
          {/*  size="small"*/}
          {/*  variant="outlined"*/}
          {/*>*/}
          {/*  <FilterListIcon className={classes.filterIcon} /> Show filters*/}
          {/*</Button>*/}
        </div>
      }
      name="header_customer_auth_history"
      title={<div className={clsx([classes.container, classes.padding5])}>
        Auth History
      </div>}
    />
    <Divider />
    <CardContent className={classes.content}>
      {history.length > 0 && !isProgressIn &&
      <Table>
        {/*<TableHead>*/}
        {/*  <TableRow>*/}
        {/*    <TableCell className={classes.firstCell}> Modified Fields </TableCell>*/}
        {/*    <TableCell className={classes.tableCell}> Old Value </TableCell>*/}
        {/*    <TableCell className={classes.tableCell}> New Value </TableCell>*/}
        {/*  </TableRow>*/}
        {/*</TableHead>*/}
        <TableBody>
          {history.map((history, id) => <HistoryComponent
            history={history}
            id={id}
            key={id}
          />)}
        </TableBody>
      </Table>
      }
      {isProgressIn && <div className={classes.spinnerRoot}>
        <CircularProgress size={60} />
      </div>}
      {history.length === 0 && <div className={classes.spinnerRoot}>
        <Typography variant={'subtitle2'}>Auth history is empty</Typography>
      </div>}
    </CardContent>
    {openFilters && <HistoryFilter
      customerId={customerId}
      onClose={handleFiltersClose}
      onConfirm={getCustomerHistory}
      open={openFilters}
    />}
  </Card>);
};

AuthHistory.propTypes = {
  customerId: PropTypes.string
};

export default AuthHistory;
