import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Button, List, Popover, LinearProgress, Paper, Input, Typography} from '@material-ui/core';
import {Clear} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';

import {axios, axios_v2, getToastHttpError} from '../../../../utils';
import {CustomerItem} from '../CustomerItem';
import {ProductItem} from '../ProductItem';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  search: {
    float: 'left',
    minWidth: 200,
    maxWidth: 600,
    flexGrow: 1,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchInput: {
    flexGrow: 1,
    flexBasis: 600,
  },
  clearIcon: {
    maxWidth: 15,
    cursor: 'pointer'
  },
  searchButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  popover: {
    padding: 16,
    minWidth: 200,
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

export const CustomSearch = ({ customers, products }) => {
  const classes = useStyles();
  const anchorElRef = useRef(null);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [list, setLists] = useState([]);
  const [isProgress, setProgress] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { store_type } = useSelector(({ createOrder }) => createOrder);

  const isBestBuy = useMemo(() => store_type === 'electronics', [store_type]);

  const prepareParams = useCallback(({ queryText }) => {
    const params = {};
    if(queryText && queryText.length >= 3) { customers ? params.q = encodeURIComponent(queryText) : params.search = queryText }
    return params
  }, [customers]);

  const handleResponse = useCallback((promise) => {
    setProgress(true);
    promise.then(response => {

      if (response?.data?.data) {
        setLists(response.data.data);
      } else {
        setLists([]);
      }
      setProgress(false);
    }).catch(response => {
      setProgress(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const handleSubmit = useCallback(() => {
    if (value?.length >= 3) {
      if (customers) {
        handleResponse(axios().get('/customers', {
          params: {
            page: 0,
            limit: 25,
            with_orders: true,
            ...prepareParams({queryText: value})
          }
        }))
      }
      if (products) {
        if (isBestBuy) {
          handleResponse(axios().get('/products', {
            params: {
              page: 0,
              limit: 25,
              dd_available: true,
              ...prepareParams({queryText: `"${value}"`})
            }
          }))
        } else {
          handleResponse(axios_v2().get('/private/search/products', {
            params: {
              page: 0,
              limit: 25,
              dd_available: true,
              ...prepareParams({queryText: String(value)})
            }
          }))
        }
      }

      setAnchorEl(anchorElRef.current)
    }
  }, [prepareParams, value, handleResponse, customers, products, isBestBuy])

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClear = useCallback(() => {
    dispatch({
      type: 'CREATE_ORDER_SET_VALUE',
      payload: { key: 'search', value: ''}
    });
    setValue('');
  }, [dispatch]);

  const handleChange = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_VALUE',
      payload: { key: event.target.name, value: event.target.value}
    });
    setValue(event.target.value);
  }, [dispatch]);

  return (
    <div
      className={classes.root}
    >
      <Paper
        className={classes.search}
        elevation={1}
      >
        <Input
          autoComplete={'off'}
          className={classes.searchInput}
          disableUnderline
          name="search"
          onChange={handleChange}
          onKeyUp={event => event.keyCode === 13 && handleSubmit()}
          placeholder={customers ? 'Search by customer name or email address' : 'Search by SKU.'}
          ref={anchorElRef}
          size="small"
          value={value}
          variant="outlined"
        />
        <Clear
          className={classes.clearIcon}
          fontSize={'small'}
          onClick={handleClear}
        />
      </Paper>
      <Button
        className={classes.searchButton}
        name="search_main_button"
        onClick={handleSubmit}
        size="small"
        type="submit"
        variant="outlined"
      >
        Search
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List className={classes.popover}>
          {isProgress ? (
            <LinearProgress />
          ) : list.length > 0 ? (
            list.map((elem, index) => (
              <React.Fragment key={index}>
                {customers &&
                  <CustomerItem
                    customer={elem}
                    onClose={handleClose}
                  />}
                {products &&
                  <ProductItem
                    onClose={handleClose}
                    product={elem}
                  />}
              </React.Fragment>
            ))
          ) : (
            <Typography variant={'subtitle1'}>{`No ${customers ? 'customers' : 'products'} found for ${value}`}</Typography>
          )}
        </List>
      </Popover>
    </div>
  )
}

CustomSearch.propTypes = {
  customers: PropTypes.bool,
  products: PropTypes.bool,
}
