import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Label } from 'components';
import {
  Grid,
  Divider,
  Typography,
  colors, Switch, Button
} from '@material-ui/core';
import Stepper from '../Stepper'
import MediaCard from '../MediaCard'
import NearestStoreList from '../NearestStoreList'
import ShippingOptionsList from '../ShippingOptionsList'
import moment from 'moment';
import axios from 'utils/axios.js';
import { getToastHttpError } from 'utils/common';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { DialogOrderItemEdit } from '../DialogOrderItemEdit';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Hidden from '@material-ui/core/Hidden';
import RoomIcon from '@material-ui/icons/Room';
import _isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  itemIndex: {
    fontWeight: 'bold!important',
    fontSize: 'initial'
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#FFFFFF'
  },
  tags: {
    borderBottom: '1px solid #eeeeee',
    padding: theme.spacing(2),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  tagLabel: {
    whiteSpace: 'normal',
    height: 'auto'
  },
  tagLine: {
    paddingRight: theme.spacing(2),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  tagInfo: {
    paddingBottom: theme.spacing(2),
  },
  imageBlock: {
    width: 'auto',
    height: 182,
    '& > *': {
      margin: '0 auto'
    }
  },
  imageH100: {
    maxHeight: '100%'
  },
  productList: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '0.875rem'
  },
  bgGray: {
    backgroundColor: '#fafafa'
  },
  listHeader: {
    fontWeight: '500',
    borderBottom: '1px solid #eeeeee',
    whiteSpace: 'nowrap',
    minHeight: '52px'
  },
  productListCell: {
    borderBottom: '1px solid #eeeeee',
    '& > *': {
      padding: '16px'
    }
  },
  minW130: {
    minWidth: '130px'
  },
  cross: {
    textDecorationLine: 'line-through'
  },
  comment: {
    width: '100%'
  },
  tagPrice: {
    border: 'none!important',
    fontSize: '12px'
  },
  textLabel: {
    textTransform: 'uppercase',
    paddingTop: '4px'
  },
  subheader: {
    fontSize: '16px',
    fontWeight: '500',
    padding: '0 0 16px 16px'
  },
  link: {
    color: colors.blue[900],
    '&:hover': {
      color: colors.blue[800],
    }
  },
  linkText: {
    color: 'inherit',
    '&:hover': {
      color: colors.blue[900],
    }
  },
  address: {
    textTransform: 'capitalize',
  }
}));

const itemStatusColors = {
  'Canceled': colors.grey[600],
  'Returned': colors.red[600],
  'Delivered': colors.blue[600],
  'Getting it Ready': colors.green[600],
  'Pre-Ordered': colors.green[600],
  'Shipped': colors.green[600],
  'In Transit': colors.green[600],
  'In Progress': colors.green[600],
  'Out for Delivery': colors.green[600],
  'Not Available': colors.grey[600],
  'Plan Activated': colors.lightBlue[600],
  'Unpaid': colors.red[600],
  'Being Processed': colors.deepPurple[600],
  'On Hold': colors.yellow[600],
  'Completed': colors.blue[600],
  'LTL Check': colors.blueGrey[600],
  'Pending Charges': colors.orange[600],
  'Abnormal order': colors.lightGreen[600],
};

const deliveryColors = {
  'store': colors.green[600],
  'address': colors.blue[600]
};

const OrderItem = props => {
  const { order, item, index, edit_mode, dataReloadInit } = props;

  const classes = useStyles();

  const isBestBuy = _isEmpty(order?.supplier) || order?.supplier === 'bestbuy';

  const isStepperStatus = (status) => {
    return (!status || ['Pending', 'Order Received', 'Preparing', 'Ready to Pick Up', 'Picked Up'].includes(status));
  };

  const available_at = item.available_at ? item.available_at.split('T')[0] : undefined;

  const [monitoringEnabled, setMonitoringEnabled] = useState(!(item.monitoring_disabled));

  const sendMonitoringState = (state) => {
    let data = {
      sku: parseInt(item.sku,10),
      monitoring_disabled: !state
    };

    return axios().put(`orders/${order.id}/item_monitoring_state`, data).then(() => {
      return 'Monitoring State saved successfully';
    }).catch(response => {
      getToastHttpError(response || 'Something Went Wrong');
      return Promise.reject('Request Failed');
    });
  };

  const handleChange = event => {
    event.persist();
    setMonitoringEnabled(event.target.checked);
    sendMonitoringState(event.target.checked).then((success_message) => {
      toast.success(success_message);
    }).catch( () => {
      setMonitoringEnabled(!event.target.checked);
    });
  };

  const [isOpenEdit, setOpenEdit] = useState(false);

  const openEdit = useCallback(() => {
    setOpenEdit(true);
  }, [setOpenEdit]);

  const closeEdit = useCallback(async (reload=false) => {
    setOpenEdit(false);
    if (reload) dataReloadInit();
  }, [setOpenEdit, dataReloadInit]);


  // product_status is OPTIONAL !!!

  const costNotReconciled =  !!item.bb_price && item.bb_price !== (parseFloat(item.base_cost_price) || 0.0) && !item.parent_sku;

  const base_price_custom = item.base_price_custom;

  const use_custom_price_in_lease = item.use_custom_price_in_lease;

  const bb_price = item.bb_price;

  const comment = item.comment;


  const costNotReconciledMessage =  'Differ Price on BestBuy';

  const customPriceMessage = 'Custom Price Assigned';

  const customPriceOnLeaseMessage = 'Custom Price Will Be Used on Acima Finalize Transaction Request';

  // ==== Display shipping address for product
  const fulfillment = item.fulfillment;
  let deliveryAddress = {};
  let storeAddress = {};
  if (isBestBuy) {
    if (!_isEmpty(item.delivery_address)) {
      deliveryAddress = item.delivery_address;
    } else if (!_isEmpty(order.shipping_address)) {
      deliveryAddress = order.shipping_address;
    }
    if (!_isEmpty(item.store_address)) {
      storeAddress = item.store_address;
    } else if (!_isEmpty(order.bb_stores_address)) {
      storeAddress = order.bb_stores_address;
    }

    if (item.fulfillment === 'store') {
      deliveryAddress = {};
    }
  } else {
    deliveryAddress = order.shipping_address;
  }
  //=====

  return (
    <div className={classes.inner}>
      <div className={classes.productList}>
        <Grid
          container
          spacing={0}
        >
          <Grid
            className={classes.productListCell}
            container
            direction="column"
            item
            lg={3}
            sm={4}
            xs={12}
          >
            <Grid
              className={clsx(classes.listHeader, classes.itemIndex, classes.bgGray)}
              item
            >
              {index && <span>#{index}</span>}
            </Grid>
            <Grid
              className={classes.imageBlock}
              item
            >
              <MediaCard
                className={classes.imageH100}
                productDetails={item}
              />
            </Grid>
          </Grid>

          <Grid
            alignItems="baseline"
            className={clsx(classes.minW130, classes.productListCell)}
            container
            item
            lg={3}
            sm
            xs={12}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              item
              xs={12}
            >
              Product Name
            </Grid>
            <Grid
              className={classes.name}
              item
              xs={12}
            >
              <span>{item.name}</span>
            </Grid>
          </Grid>

          <Grid
            alignItems="baseline"
            className={classes.productListCell}
            container
            item
            sm
            xs={6}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              item
              xs={12}
            >
              Sku
            </Grid>
            <Grid
              className={classes.name}
              item
              xs={12}
            >
              {item.sku}
            </Grid>
          </Grid>

          <Grid
            alignItems="baseline"
            className={classes.productListCell}
            container
            item
            sm
            xs={6}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
            >
              Quantity
            </Grid>
            <Grid
              className={classes.name}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
            >
              {item.quantity}
            </Grid>
          </Grid>

          {(item.base_cost_price !== undefined || item.bb_price !== undefined) && <Grid
            alignItems="baseline"
            className={classes.productListCell}
            container
            item
            sm
            xs={6}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
            >
              Goods Cost
            </Grid>
            <Grid
              className={classes.name}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
            >
              { !base_price_custom && <Typography>
                ${parseFloat(item.base_cost_price || item.bb_price).toFixed(2)}
              </Typography> }
              { base_price_custom && <Typography
                className={classes.cross}
                color="textSecondary"
              >
                ${parseFloat(item.base_cost_price || item.bb_price).toFixed(2)}
              </Typography> }
              { base_price_custom && <Grid
                alignItems="center"
                container
                direction="row"
                justifyContent="flex-end"
                style={{flexWrap: 'initial'}}
              >
                <Grid item>
                  <ErrorOutlinedIcon style={{ color: colors.red[600] }} />
                </Grid>
                <Grid item>
                  &nbsp;${base_price_custom.toFixed(2)}
                </Grid>
              </Grid> }
            </Grid>
          </Grid>}

          {item.price_tax!== undefined && <Grid
            alignItems="baseline"
            className={classes.productListCell}
            container
            item
            sm
            xs={6}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
            >
              BC Tax
            </Grid>
            <Grid
              className={classes.name}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
              name="bc_tax"
            >
              ${parseFloat(item.price_tax).toFixed(2)}
            </Grid>
          </Grid>}

          {item.bb_tax!== undefined && <Grid
            alignItems="baseline"
            className={classes.productListCell}
            container
            item
            sm
            xs={6}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
            >
              BB Tax
            </Grid>
            <Grid
              className={classes.name}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
              name="bb_tax"
            >
              ${parseFloat(item.bb_tax).toFixed(2)}
            </Grid>
          </Grid>}

          {item.parent_sku && item.bb_price && <Grid
            alignItems="baseline"
            className={classes.productListCell}
            container
            item
            sm
            xs={6}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
            >
              BB Price
            </Grid>
            <Grid
              className={classes.name}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
              name="bb_price"
            >
              ${item.bb_price.toFixed(2)}
            </Grid>
          </Grid>}

          {item.total_inc_tax !== undefined && item.total_inc_tax !== null && <Grid
            alignItems="baseline"
            className={classes.productListCell}
            container
            item
            sm
            xs={6}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
            >
              Total Price
            </Grid>
            <Grid
              className={classes.name}
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
              name="total_price"
            >
              ${parseFloat(item.total_inc_tax).toFixed(2)}
            </Grid>
          </Grid>}
        </Grid>

        {/*{ (costNotReconciled || base_price_custom || use_custom_price_in_lease || comment) &&*/}
        <Grid
          className={classes.productListCell}
          container
        >

          { edit_mode && !item.parent_sku && <Grid
            alignItems="baseline"
            className={classes.btnEdit}
            container
            direction="row"
            item
            justifyContent="flex-end"
            xs={12}
          >
            <Button
              color="secondary"
              onClick={openEdit}
              size="small"
              variant="outlined"
            >
              Edit
            </Button>
          </Grid> }

          { (costNotReconciled || base_price_custom || use_custom_price_in_lease) &&
          <Grid
            container
            direction="column"
            item
            sm={5}
            xs={12}
          >
            { costNotReconciled &&
            <Grid
              alignItems="baseline"
              container
              item
            >
              <Grid
                alignItems="center"
                className={classes.tagInfo}
                container
                direction="row"
                item
              >
                <Grid item>
                  <ErrorOutlinedIcon style={{ color: colors.red[600] }} />
                </Grid>
                <Grid
                  className={classes.tagLine}
                  item
                >
                  <Label
                    className={clsx(classes.tagLabel, classes.tagPrice)}
                    color={colors.orange[600]}
                    variant="outlined"
                  >
                    {costNotReconciledMessage +':'}
                  </Label>
                </Grid>
                <Grid
                  className={classes.tagLine}
                  item
                >
                  ${bb_price?.toFixed(2)}
                </Grid>
              </Grid>
            </Grid> }

            { base_price_custom &&<Grid
              alignItems="baseline"
              container
              item
            >
              <Grid
                alignItems="center"
                className={classes.tagInfo}
                container
                direction="row"
                item
              >
                <Grid item>
                  <ErrorOutlinedIcon style={{ color: colors.red[600] }} />
                </Grid>
                <Grid
                  className={classes.tagLine}
                  item
                >
                  <Label
                    className={clsx(classes.tagLabel, classes.tagPrice)}
                    color={colors.orange[600]}
                    variant="outlined"
                  >
                    {customPriceMessage +':'}
                  </Label>
                </Grid>
                <Grid
                  className={classes.tagLine}
                  item
                >
                  ${base_price_custom.toFixed(2)}
                </Grid>
              </Grid>
            </Grid> }

            { use_custom_price_in_lease &&<Grid
              container
              item
            >
              <Grid
                alignItems="center"
                className={classes.tagInfo}
                container
                direction="row"
                item
              >
                <Grid item>
                  <ErrorOutlinedIcon style={{ color: colors.red[600] }} />
                </Grid>
                <Grid
                  className={classes.tagLine}
                  item
                  style= {{ marginLeft: '10px' }}
                  xs
                >
                  <Label
                    className={classes.tagLabel}
                    color={colors.orange[600]}
                    variant="outlined"
                  >
                    {customPriceOnLeaseMessage}
                    <CheckOutlinedIcon style={{ color: colors.green[600] }} />
                  </Label>
                </Grid>
              </Grid>
            </Grid> }
            <Hidden xsDown>
              <Divider orientation="vertical" />
            </Hidden>
          </Grid>}

          { comment &&
          <Grid
            container
            direction="column"
            item
            justifyContent="flex-start"
            sm={6}
            xs={12}
          >
            <Grid
              className={classes.tagInfo}
              item
            >
              <div className={classes.tagLine}>
                <Typography
                  className={clsx(classes.textLabel, classes.tagPrice)}
                  style={{ color: colors.orange[600] }}
                  variant="h5"
                >
                  Comments:
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.comment}
              item
            >
              <Typography color="textSecondary">
                {comment}
              </Typography>
            </Grid>
          </Grid> }
        </Grid>

        {!item.is_complex && <div>
          {((!item.status && order.bot_status === 'Cancelled') ? item.status = 'Awaiting Status' : item.status = item.status || 'Awaiting Status')
            && isStepperStatus(item.status || '') ? <Stepper status={item.status || ''} name="delivery_status"/> : <div className={classes.tags}><Label
              color={itemStatusColors[item.status]}
              variant={item.status==='Awaiting Status' ? 'outlined' : 'contained'}
              name="delivery_status"
            >
              {item.status}
            </Label></div>}
        </div>}

        {!item.is_complex && <Grid
          alignItems="center"
          className={clsx(classes.productListCell, classes.bgGray)}
          container
          direction="row"
        >
          <Grid
            item
            xs={3}
          >
            {fulfillment === 'store' && <Typography variant="subtitle2">
              Estimated Delivery Date
            </Typography>}
            {fulfillment === 'address' && <Typography variant="subtitle2">
              Estimated Arrival Date
            </Typography>}
          </Grid>
          <Grid
            item
            xs
          >
            <Typography variant="h6" name="text_available_at">{available_at ? moment(available_at).format(
              'DD MMM YYYY'
            ) : <Typography variant="subtitle2" name="text_available_at">N/A</Typography>}
            </Typography>
          </Grid>
          <Grid>
            { edit_mode && order.ext_id && <div>
              <Typography variant="h6" color="error">Used In Order Status Monitoring</Typography>
              <Typography variant="body2">
                Means that the product status will be used in order status monitoring
              </Typography>
              <Switch
                checked={monitoringEnabled}
                color="primary"
                edge="start"
                name="enable"
                onChange={handleChange}
              />
            </div>}
            { (!edit_mode && order.ext_id && item?.monitoring_disabled) && <div>
              <Typography variant="h6">Used In Order Status Monitoring  &nbsp;  &nbsp;  &nbsp;
                {<Label
                  color={colors.red[600]}
                  variant="outlined"
                >No</Label>}
              </Typography>
            </div>}
          </Grid>
        </Grid>}

        {order.fulfillment === 'address' && <Grid
          alignItems="center"
          className={clsx(classes.productListCell/*, classes.bgGray*/)}
          container
          direction="row"
        >
          <Grid
            item
            xs={3}
          >
            <Typography variant="subtitle2">
              Delivery to
            </Typography>
          </Grid>
          <Grid
            item
            xs
          >
            {fulfillment === 'address' && <Label
              color={deliveryColors[fulfillment]}
              variant="outlined"
              name="label_delivery_to"
            >
              The Home
            </Label>}
            {fulfillment === 'store' && <Label
              color={deliveryColors[fulfillment]}
              variant="outlined"
              name="label_delivery_to"
            >
              BestBuy Store
            </Label>}
            {fulfillment !== 'address' && fulfillment !== 'store' &&
              <span name="label_delivery_to">N/A</span>}
          </Grid>
        </Grid>
        }

        {(order.fulfillment === 'address' && !_isEmpty(deliveryAddress)) && <Grid
          alignItems="center"
          className={clsx(classes.productListCell/*, classes.bgGray*/)}
          container
          direction="row"
        >
          <Grid
            item
            xs={3}
          >
            <Typography variant="subtitle2">
              Delivery address
            </Typography>
          </Grid>
          <Grid
            item
            xs
            name="delivery_address"
          >
            <div
              className={classes.address}
            >{deliveryAddress?.firstname || ''} {deliveryAddress?.lastname || ''}</div>
            <div
              className={classes.address}
            >{deliveryAddress?.address1 || ''}</div>
            {deliveryAddress?.address2 && <div
              className={classes.address}
            >{deliveryAddress?.address2 || ''}</div>}
            <div
              className={classes.address}
            >{deliveryAddress?.city || ''}, {deliveryAddress?.state || ''} {deliveryAddress?.postalcode || ''}</div>
            <div
              className={classes.address}
            >{deliveryAddress?.country || ''}</div>
            <div>{deliveryAddress?.phonenumber || ''}</div>
          </Grid>
        </Grid>
        }

        {!_isEmpty(storeAddress) && <Grid
          alignItems="center"
          className={clsx(classes.productListCell/*, classes.bgGray*/)}
          container
          direction="row"
        >
          <Grid
            item
            xs={3}
          >
            <Typography variant="subtitle2">
              Store address
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
          >
            <a
              className={classes.linkText}
              href={'http://maps.google.com/maps?q=' + encodeURIComponent(storeAddress?.longName + ',' + storeAddress?.address + ',' + storeAddress?.city + ',' + storeAddress?.region + ',' + storeAddress?.postalCode)}
              rel="noopener noreferrer"
              target="_blank"
              name="store_address"
            >
              <div>{storeAddress?.longName}</div>
              <div>{storeAddress?.address}</div>
              <div>{storeAddress?.city}, {storeAddress?.region} {storeAddress?.postalCode}</div>
            </a>
          </Grid>
          <Grid
            item
            xs={1}
          >
            <a
              className={classes.link}
              href={'http://maps.google.com/maps?q=' + encodeURIComponent(storeAddress?.longName + ',' + storeAddress?.address + ',' + storeAddress?.city + ',' + storeAddress?.region)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <RoomIcon />
            </a>
          </Grid>
        </Grid>
        }

      </div>

      {isBestBuy && <NearestStoreList
        item={item}
        zip={order.billing_address?.zip}
      />}
      <Divider/>

      {isBestBuy && <ShippingOptionsList
        item={item}
        zip={order.billing_address?.zip}
      />}
      <Divider className={classes.divider}/>

      {isOpenEdit && <DialogOrderItemEdit
        closeDialog={closeEdit}
        isOpen={isOpenEdit}
        orderId={order.id}
        product_data={item}
      />}

      {item.is_complex && <h4 className={classes.subheader}>Order item <span>#{index}</span> includes</h4>}

    </div>
  )
};

OrderItem.propTypes = {
  className: PropTypes.string,
  edit_mode: PropTypes.bool,
  // index: PropTypes.number,
  item: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};

export default OrderItem;
