import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Collapse,
  Button,
  IconButton,
  TableCell,
  TableRow,
  colors
} from '@material-ui/core';

import {toastHttpError, useFiltersSearch} from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';

import {KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons';
import {getUserInfo} from '../../../../../utils/resources';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    // justifyContent: 'center'
  },
  isFraud: {
    color: colors.red[900] + ' !important',
  },
  isFraudLabel: {
    fontWeight: 'bold',
    backgroundColor: colors.red[600] + '!important',
  },
  linkText: {
    color: colors.grey[800],
    '&:hover': {
      color: colors.blue[900]
    }
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },

  tableWrapper: {
    height: 'calc(100vh - 198px)',
    overflow: 'auto'
  },
  sendGridCategory: {
    width: 150,
    maxWidth: '100%'
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  spinnerRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: '4px',
    top: 4,
    minWidth: 70
  },
  mainText: {
    color: colors.blue[900],
    padding: 0
  },
  address: {
    textTransform: 'capitalize'
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  createdByLeaseProvider: {
    minWidth: 180,
    maxWidth: '100%',
    paddingTop: 0,
    paddingBottom: 0

  },
  fulfillment_tag: {
    marginRight: theme.spacing(1),
    marginBottom: '1px'
  },
  createdText: {
    color: colors.grey[500]
  },
  tableItem: {
    verticalAlign: 'top',
    width: '100%'
  },
  columnLeaseProvider: {
    maxWidth: '100%',
    marginBottom: '5px'
  },
  phoneBox: {
    padding: 0
  },
  divider: {
    marginBottom: '5px',
    marginTop: '5px',
    backgroundColor: colors.grey[300]
  },
  row: {
    display: 'flex',
    // alignItems: 'center',
    width: '100%'
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: 0,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:687px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  expired: {
    color: colors.red[600],
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  approvalAmountDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 4
  },
  key: {
    fontWeight: 600,
    minWidth: 130,
    maxWidth: 220,
  },
  value: {
    marginLeft: 10,
    width: 250,
    marginRight: 10
  },
  approvalAmountKey: {
    fontWeight: 600,
    minWidth: 220,
  },
  approvalAmountValue: {
    fontWeight: 600,
    color: colors.blue[900],
    width: 60,
    marginRight: 10
  },
  keyStep: {
    fontWeight: 600,
    minWidth: 220,
  },
  valueStep: {
    marginLeft: 10,
    width: 60,
    marginRight: 10
  },
  keyAuth: {
    fontWeight: 600,
    minWidth: 80,
    textAlign: 'center'
  },
  valueAuth: {
    marginLeft: 10,
    // width: 140,
    marginRight: 10
  },
  name: {
    fontWeight: 600,
  },
  blocks: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    justifyContent: 'center',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
  },
  authHistoryAttempt: {
    display: 'flex',
    flexDirection: 'row',
    height: 22,
    minWidth: 140,
  },
  activeAuthHistoryAttempt: {
    display: 'flex',
    flexDirection: 'row',
    height: 22,
    // backgroundColor: 'lightgrey',
    minWidth: 140,
    borderColor: '#116897',
    border: 'solid',
    borderRadius: 6,
    borderWidth: 'thin'
  },
}));


const User = props => {

  const { user={} } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [authHistory, setAuthHistory] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [authAttempt, setAuthAttempt] = useState(0);
  const [authHistoryError, setAuthHistoryError] = useState('');
  const [inProgress, setInProgress] = useState(false);

  const [name, setName] = useState(`${user.first_name} ${user.last_name}`);
  const [email, setEmail] = useState(user.email);

  const { step, loss, fromDate, toDate, oid, pid, sid, eid, storeType } = useFiltersSearch();

  const steps = useMemo(() => {

    let isApplicationApproved = '';
    if (authHistory[authAttempt]?.is_approved === true) {
      isApplicationApproved = 'Yes';
    } else if (authHistory[authAttempt]?.is_approved === false) {
      isApplicationApproved = 'No';
    }

    return {
      step1: authHistory[authAttempt]?.last_step > 0 ? 'Yes' : '',
      step2: authHistory[authAttempt]?.last_step > 1 ? 'Yes' : '',
      step3: authHistory[authAttempt]?.last_step > 2 ? 'Yes' : '',
      step4: authHistory[authAttempt]?.last_step > 3 ? 'Yes' : '',
      step5: authHistory[authAttempt]?.last_step > 4 ? 'Yes' : '',
      step6: authHistory[authAttempt]?.last_step > 5 ? 'Yes' : '',
      step7: authHistory[authAttempt]?.last_step > 6 ? 'Yes' : '',
      step8: isApplicationApproved ? isApplicationApproved : '',
      step9: authHistory[authAttempt]?.last_step > 8 ? 'Yes' : '',
      step10: authHistory[authAttempt]?.last_step > 9 ? 'Yes' : '',

      approvalAmount: authHistory[authAttempt]?.approvalAmount || authHistory[authAttempt]?.approval_amount
    }
  }, [authHistory, authAttempt]);

  // const formatTimestamp = (timestamp) => timestamp.replace('T', ' ').slice(0, timestamp.length - 8);
  // const formatTimestamp = (timestamp) => new Date(timestamp).toString().slice(4, 21);
  const formatTimestamp = (timestamp) => new Date(timestamp).toLocaleString();

  const handleSetAuthAttempt = (index) => {
    setAuthAttempt(index);
  }

  const handleUserInfo = useCallback((authHistoryPromise) => {
    setAuthHistory([]);
    setInProgress(true);

    authHistoryPromise.then(response => {
      setAuthHistory(response.data?.authHistories || []);
      setCustomerDetails(response.data?.customerDetails || []);

      setInProgress(false);
    }).catch(response => {
      setInProgress(false);
      if (response?.response?.data?.message) {
        setAuthHistoryError(response?.response?.data?.message);
      }
      setAuthHistory([]);
      toastHttpError(response);
    });
  }, [setAuthHistory]);


  const prepareParams = useCallback(({fromDate, toDate, oid, pid, sid, eid, storeType, step, loss}) => {
    const params = {};
    if (oid) { params.oid = oid;}
    if (pid) { params.pid = pid;}
    if (sid) { params.sid = sid;}
    if (eid) { params.eid = eid;}
    if (step) { params.step = step;}
    if (loss) { params.loss = loss;}
    if (storeType) { params.storeType = storeType;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString());}
    return params
  }, []);

  useEffect(() => {
    if (open && user.phone) {

      const params = prepareParams({ fromDate, toDate, oid, pid, sid, eid, storeType, step, loss});
      
      handleUserInfo(getUserInfo(user.phone, params));
      if(customerDetails.firstName || customerDetails.lastName) setName(`${customerDetails.firstName} ${customerDetails.lastName}`);
      if(customerDetails.email) setEmail(customerDetails.email);
    }
  }, [handleUserInfo, open, user.phone, name, email, customerDetails.firstName, customerDetails.lastName, customerDetails.email, fromDate, toDate, oid, pid, sid, eid, storeType, step, loss, prepareParams]);

  return (
    <React.Fragment>

      <TableRow
        aria-label="expand row"
        className={classes.tableItem}
        // key={++tableId}
        hover
        key={user.id}
        onClick={() => setOpen(!open)}
        size="small"
      >

        <TableCell>
          <IconButton
            aria-label="expand row"
            onClick={() => setOpen(!open)}
            size="small"
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        <TableCell className={classes.name}>{name || ''}</TableCell>
        <TableCell>{user.phone || ''}</TableCell>
        <TableCell>{email || ''}</TableCell>

      </TableRow>
      <TableRow>
        <TableCell
          colSpan={6}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <div className={classes.blocks}>
              <div className={classes.block}>
                <div className={classes.detailsRow}>
                  <div className={classes.key}>Date of Birth</div>
                  <div className={classes.value}>{customerDetails.dob?.slice(0, 10) || ''}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.key}>Address</div>
                  <div className={classes.value}>{customerDetails.address || ''}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.key}>Employer</div>
                  <div className={classes.value}>{customerDetails.employerName || ''}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.key}>Last Pay Date</div>
                  <div className={classes.value}>{customerDetails.lastPayDate?.slice(0, 10) || ''}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.key}>Next Pay Date</div>
                  <div className={classes.value}>{customerDetails.nextPayDate?.slice(0, 10) || ''}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.key}>Payment Frequency</div>
                  <div className={classes.value}>{customerDetails.payFrequency || ''}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.key}>Annual Income</div>
                  <div className={classes.value}>{customerDetails.annualIncome || ''}</div>
                </div>
                {!!customerDetails?.approvalAmount && (
                  <div className={classes.detailsRow}>
                    <div className={classes.key}>Approval Amount</div>
                    <div className={classes.value}>{customerDetails.annualIncome || 0}</div>
                  </div>
                )}
                <div className={classes.detailsRow}>
                  <div className={classes.key}>Created&nbsp;Date</div>
                  <div className={classes.value}>{customerDetails.createdAt?.slice(0, 10) || ''}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.key}>Updated&nbsp;Date</div>
                  <div className={classes.value}>{customerDetails.updatedAt?.slice(0, 10) || ''}</div>
                </div>
              </div>

              <div className={classes.block}>

                {inProgress && <div className={classes.spinnerRoot}>
                  <CircularProgress size={60} />
                </div>}

                <div className={classes.detailsRow}>
                  <div className={classes.keyStep}>Step 1: Phone entered</div>
                  <div className={classes.valueStep}>{steps.step1}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.keyStep}>Step 2: Code entered</div>
                  <div className={classes.valueStep}>{steps.step2}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.keyStep}>Step 3: Basic info entered</div>
                  <div className={classes.valueStep}>{steps.step3}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.keyStep}>Step 4: Address entered</div>
                  <div className={classes.valueStep}>{steps.step4}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.keyStep}>Step 5: Address validation passed</div>
                  <div className={classes.valueStep}>{steps.step5}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.keyStep}>Step 6: Employment info entered</div>
                  <div className={classes.valueStep}>{steps.step6}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.keyStep}>Step 7: Summary passed</div>
                  <div className={classes.valueStep}>{steps.step7}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.keyStep}>Step 8: Is approved</div>
                  {/*<div className={classes.valueStep}>{isApproved ? 'Yes' : 'No'}</div>*/}
                  <div className={classes.valueStep}>{steps.step8}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.keyStep}>Step 9: Checkout modal clicked</div>
                  <div className={classes.valueStep}>{steps.step9}</div>
                </div>
                <div className={classes.detailsRow}>
                  <div className={classes.keyStep}>Step 10: Order created</div>
                  <div className={classes.valueStep}>{steps.step10}</div>
                </div>

                {!!steps.approvalAmount && (
                  <div className={classes.approvalAmountDetailsRow}>
                    <div className={classes.approvalAmountKey}>Approval Amount</div>
                    <div className={classes.approvalAmountValue}>{`$${steps.approvalAmount}`}</div>
                  </div>
                )}

              </div>

              {authHistory.length > 0 ? <div className={classes.block}>

                {/*{authHistory.map((authAttempt, index) => <div*/}
                {/*  className={index === 1 ? classes.detailsRow : classes.activeAuthHistoryRecord}*/}
                {/*  key={index}*/}
                {/*>*/}
                {/*  <div className={classes.keyAuth}>Authorization</div>*/}
                {/*  <div className={classes.valueAuth}>{formatTimestamp(authAttempt.last_auth_timestamp)}</div>*/}
                {/*</div> )}*/}
                <div className={classes.keyAuth}>Authorization</div>

                {authHistory.map((authRecord, index) => <Button
                  className={authAttempt === index ? classes.activeAuthHistoryAttempt : classes.authHistoryAttempt}
                  key={index}
                  name={'auth-attempt'}
                  onClick={() => handleSetAuthAttempt(index)}
                >
                  <div className={classes.valueAuth}>{formatTimestamp(authRecord.last_auth_timestamp)}</div>
                  {/*<div className={classes.valueAuth}>{`${authAttempt} ${index} ${authAttempt === index}`}</div>*/}

                </Button> )}

              </div> : <div className={classes.block}>
                <div className={classes.keyAuth}>Authorization</div>
                <div className={classes.valueAuth}>{authHistoryError ? authHistoryError : 'No authorization'}</div>
              </div>}

            </div>

          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>

  );
};

User.propTypes = {
  user :PropTypes.object
};

export default User;
