import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Button,
  colors, RadioGroup, FormControlLabel, Radio, Switch
} from '@material-ui/core';
import axios from 'utils/axios';
import { getToastHttpError, notifySuccess } from '../../../../utils';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  textField: {
    margin: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  switch: {
    marginLeft: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));


const orderStatusOptions = {
  'bestbuy': [
    'Pending',
    'Placed With BestBuy',
    'Ready For Pickup',
    'Fulfillment Confirmed',
    'Lease Initiated',
    'Lease Confirmed',
    'Cancelled'
  ],
  'giga': [
    'Pending',
    'Placed With Giga',
    'Being Processed',
    'Fulfillment Confirmed',
    'Lease Initiated',
    'Lease Confirmed',
    'Cancelled'
  ],
};


function nextStatus(prevStatus, supplier='bestbuy') {
  let nextStatus;
  switch (prevStatus) {
    case 'Pending':
      nextStatus = supplier === 'bestbuy' ? 'Placed With BestBuy' : 'Placed With Giga';
      break;
    case 'Placed With BestBuy':
      nextStatus = 'Ready For Pickup';
      break;
    case 'Placed With Giga':
      nextStatus = 'Being Processed';
      break;
    case 'Being Processed':
      nextStatus = 'Fulfillment Confirmed';
      break;
    case 'Ready For Pickup':
      nextStatus = 'Fulfillment Confirmed';
      break;
    case 'Fulfillment Confirmed':
      nextStatus = 'Lease Initiated';
      break;
    case 'Lease Initiated':
      nextStatus = 'Lease Confirmed';
      break;
    default:
      break;
  }
  return nextStatus;
}

function prevStatus(order) {
  // let prevStatus = order.history_ext.slice(-2, -1)[0]?.status;
  let prevStatus = order.history_ext[1].status;

  switch (prevStatus) {
    case 'Synced Placed With BestBuy':
      prevStatus = 'Placed With BestBuy';
      break;
    case 'Synced Placed With Giga':
      prevStatus = 'Placed With Giga';
      break;
    case 'Synced Being Processed':
      prevStatus = 'Being Processed';
      break;
    case 'Synced Ready For Pickup':
      prevStatus = 'Ready For Pickup';
      break;
    case 'Synced Fulfillment Confirmed':
      prevStatus = 'Fulfillment Confirmed';
      break;
    case 'Synced Lease Initiated':
      prevStatus = 'Lease Initiated';
      break;
    case 'Synced Lease Confirmed':
      prevStatus = 'Lease Confirmed';
      break;
    default:
      break;
  }

  return prevStatus;
}

const OrderStatusEdit = props => {
  const { open, onClose, onConfirm, order, orderId, /*bot_status,*/ className, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const [status, setStatus] = useState('');

  const [note, setNote] = useState();

  const [customState, setCustomState] = useState(false);

  const [inProgress, setInProgress] = useState(false);

  const dispatch = useDispatch();

  const handleChangeCustomState = event => {
    event.persist();
    setCustomState(event.target.checked);
    setStatus('');
  };

  const handleRadioChange = (event) => {
    setStatus(event.target.value);
  };

  const handleTextInput = (event) => {
    setNote(event.target.value);
  };

  const updateOrderStatus = () => {
    setInProgress(true);
    console.log(status);
    console.log(note);
    axios().put(`orders/${orderId}/set_state`,
      {
        status: status,
        note: note
      }).then((response) => {

        const payload = {
          owner_order_id: order.id,
        };
        dispatch({
          type: 'SET_OWNER_ORDER_ID',
          payload
        });

        notifySuccess(`Order #${order.id} status changed successfully`);
        dataReloadInit();
        setInProgress(false);
        onConfirm();
    }).catch(response => {
      setInProgress(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });
  };


  if (!open) {
    return null;
  }

  return (
    <Modal
      disableBackdropClick
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <Typography
            align="center"
            gutterBottom
            variant="h3"
          >
            Edit Order Status
          </Typography>

          <FormControlLabel
            className={classes.switch}
            label="Set custom status"
            labelPlacement="end"
            control={<Switch
              checked={customState}
              color="primary"
              edge="start"
              name="enable"
              onChange={handleChangeCustomState}
            />} />

          {!customState &&
          <RadioGroup
            className={classes.container}
            aria-label="quiz"
            name="quiz"
            value={status}
            onChange={handleRadioChange}
          >
            <Grid
              container
              spacing={2}
            >
              {/*{prevNextState.map((status, index) => (*/}
                <Grid
                  // key={index}
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}>
                  <FormControlLabel value={prevStatus(order)} control={<Radio />} label={prevStatus(order) + ' (Previous status)'} />
                </Grid>

              <Grid
                // key={index}
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}>
                {nextStatus(prevStatus(order), order?.supplier) && <FormControlLabel value={nextStatus(prevStatus(order), order?.supplier)} control={<Radio />} label={nextStatus(prevStatus(order), order?.supplier) + ' (Next status)'} />}
              </Grid>
              {/*))}*/}
            </Grid>
          </RadioGroup>}

          {customState && <TextField
            className={classes.textField}
            fullWidth
            label="Order status"
            name="orderStatus"
            onChange={({ currentTarget }) => setStatus(currentTarget.value)}
            select
            // eslint-disable-next-line react/jsx-sort-props
            SelectProps={{ native: true }}
            value={status}
            variant="outlined"
          >
            <option value="" />
            {orderStatusOptions[order?.supplier || 'bestbuy']?.map(option => (
              <option
                key={option}
                value={option}
              >
                {option}
              </option>
            ))}
          </TextField>}


          <TextField
            className={classes.textField}
            fullWidth
            label="Notes"
            margin="dense"
            multiline
            name="comment"
            onChange={handleTextInput}
            required
            rows={5}
            type="text"
            variant="outlined"
          />
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            onClick={onClose}
            variant="contained"
          >
            Close
          </Button>
          <Button
            className={classes.saveButton}
            disabled={inProgress || status === '' || !note}
            onClick={updateOrderStatus}
            variant="contained"
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

OrderStatusEdit.displayName = 'OrderStatusEdit';

OrderStatusEdit.propTypes = {
  className: PropTypes.string,
  customerMessage: PropTypes.any,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  orderId: PropTypes.number.isRequired,
  dataReloadInit: PropTypes.func.isRequired,
  open: PropTypes.bool
};

OrderStatusEdit.defaultProps = {
  open: false,
  onClose: () => {
  }
};

export default OrderStatusEdit;
