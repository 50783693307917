import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Link,
  TableCell,
  TableRow,
  colors
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import { axios_v2, getToastHttpError } from 'utils';
import CardMedia from '@material-ui/core/CardMedia';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import { Rating } from '@material-ui/lab';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  linkText: {
    color: colors.grey[800],
    '&:hover': {
      color: colors.blue[900],
    },
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    height: 'calc(100vh - 355px)',
    overflow: 'auto',
  },
  sendGridCategory: {
    width: 150,
    maxWidth: '100%'
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: '4px',
    top: 4,
    minWidth: 70
  },
  mainText: {
    color: colors.blue[900],
    padding: 0
  },
  address: {
    textTransform: 'capitalize',
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  createdByLeaseProvider: {
    minWidth: 180,
    maxWidth: '100%'
  },
  fulfillment_tag: {
    marginRight: theme.spacing(1),
    marginBottom: '1px',
  },
  createdText: {
    color: colors.grey[500],
  },
  tableItem: {
    verticalAlign: 'middle',
  },
  columnLeaseProvider: {
    maxWidth: '100%',
    marginBottom: '5px',
  },
  phoneBox: {
    padding: 0
  },
  divider: {
    marginBottom: '5px',
    marginTop: '5px',
    backgroundColor: colors.grey[300],
  },
  card: {
    maxWidth: 250,
  },
  media: {
    // minWidth: 250,
    backgroundSize: 'contain',
    width: '100px',
    paddingTop: '56.25%', // 16:9
  },
}));


const ProductGigaItem = props => {
  const { product, index } = props;

  const classes = useStyles();

  const [isHotList, setIsHotList] = useState(product.isHotList);

  const handleChangeHotList = async () => {
    return axios_v2().post('/private/product/update', {
      id: product._id,
      is_hot: !isHotList,
      store_type: 'furniture',
    }).then(response => {

      if (response?.data) {

        setIsHotList(!isHotList);

        if (!isHotList) {
          toast.success('The product was added to the HotList');
        } else {
          toast.success('The product was removed from the HotList');
        }

      } else {
        getToastHttpError('Something Went Wrong');
      }

    }).catch(error => {
      getToastHttpError(error || 'Something Went Wrong');
    });
  };

  const handleChangeRating = async (rating) => {
    return axios_v2().post('/private/product/update', {
      id: product._id,
      ratings: rating || 0,
      store_type: 'furniture',
    }).then(response => {

      if (response?.data) {
        toast.success('The rating has been successfully changed');
        setRating(rating);
      } else {
        getToastHttpError('Something Went Wrong');
      }

    }).catch(error => {
      getToastHttpError(error || 'Something Went Wrong');
    });
  };

  const [rating, setRating] = useState(product.customerReviewAverage)



  return (
    <TableRow
      className={classes.tableItem}
      hover
      key={index}
    >
      <TableCell>
        <Button onClick={handleChangeHotList} >
          {isHotList ? <img src='/images/hot-active.svg' alt={'Hot Active'} /> : <img src='/images/hot.svg' alt={'Not Hot Active'} />}
        </Button>
      </TableCell>
      <TableCell>
        <Link
          component={RouterLink}
          to={'/products/product_list/furniture/' + product._id}
        >
          <CardMedia
            className={classes.media}
            image={((product?.imageUrls && product.imageUrls[0]) ? product.imageUrls[0] : '/images/no_image_product.png')}
            title={(product?.name || '')}
          />
        </Link>
      </TableCell>
      <TableCell>
        <Rating
          defaultValue={rating}
          name={product._id}
          onChange={(e, newValue) => handleChangeRating(newValue)}
          size="small"
          value={rating}
        />
      </TableCell>
      <TableCell>{product?.name}</TableCell>
      <TableCell>{product?.sku}</TableCell>
      <TableCell>{product?.category}</TableCell>
      <TableCell>{product?.costPrice ? parseFloat(product.costPrice)?.toFixed(2) : ''}</TableCell>
      <TableCell>
        {(product?.ddAvailable) ?
          <CheckCircleIcon style={{ color: colors.green[600] }} /> :
          <HighlightOffIcon
            className={classes.ico}
            style={{ color: colors.red[600] }}
          />
        }
      </TableCell>
      <TableCell>{moment(product?.createdAt).format('DD MMM YYYY')}</TableCell>
      <TableCell>{moment(product?.updatedAt).format('DD MMM YYYY')}</TableCell>
      <TableCell align="right">
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to={'/products/product_list/furniture/' + product._id}
        >
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

ProductGigaItem.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  product: PropTypes.object
};

export default ProductGigaItem;
