import React, {useCallback} from 'react'
import { CardMedia, Grid, Typography } from '@material-ui/core';
import ProductIcon from '@material-ui/icons/LibraryBooks';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {useDispatch, useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    minWidth: '280px',
    '&:hover': {
      cursor: 'pointer',
      background: '#f4f6f8',
    },
  },
  media: {
    backgroundSize: 'contain',
    minWidth: '50px',
    width: '100%',
    minHeight: '50Px',
    height: '100%'
  },
}));

export const ProductItem = (props) => {
  const {product, onClose} = props
  const classes = useStyles();
  const dispatch = useDispatch();
  const { store_type } = useSelector(({ createOrder }) => createOrder);

  const handleClick = useCallback(() => {
    dispatch({
      type: 'CREATE_ORDER_SET_PRODUCTS',
      payload: product,
    });
    onClose();
  }, [dispatch, product, onClose]);

  return (
    <Grid
      className={classes.container}
      container
      mt={1}
      onClick={handleClick}
      spacing={2}

    >
      <Grid
        item
        xs={3}
      >{ product?.imageUrls?.[0] ?
        <CardMedia
          className={classes.media}
          image={(product?.imageUrls?.[0] || '/images/no_image_product.png')}
          title={(product?.name || '')}
        />
        :
        <ProductIcon fontSize={'large'} />}</Grid>
      <Grid
        item
        xs={9}
      >
        <Typography variant="subtitle1">{product?.name}</Typography>
        <Typography variant="body2">{`${product?.sku}/${store_type === 'electronics' ? product.salePrice : product.price}`}</Typography>
      </Grid>
    </Grid>
  )
}

ProductItem.propTypes = {
  onClose: PropTypes.func,
  product: PropTypes.object,
}
