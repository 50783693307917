import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '../index';
import {PAYMENT_STATUS_COLORS} from 'common';


// const statusColors = {
//   'Pending': colors.deepPurple[600],
//   'Cancelled': colors.grey[600],
//   'Placed With BestBuy': colors.blue[600],
//   // 'Synced Placed With BestBuy': colors.yellow[600],
//   'Ready For Pickup': colors.green[600],//colors.lightGreen[600],
//   // 'Synced Ready For Pickup': colors.blue[600],
//   'Fulfillment Confirmed': colors.lightBlue[600],
//   // 'Synced Fulfillment Confirmed': colors.lime[600],
//   'Lease Initiated': colors.indigo[600],
//   'Lease Confirmed': colors.teal[600],
//   // 'Synced Lease Initiated': colors.deepOrange[600],
//   'Manual Verification Required': colors.red[600],
//   'Waiting For Approve': colors.lime[600],
//   'Approved': colors.green[600],
//   'Not approved': colors.red[600],
// };

export const botStatusMasquerade = bot_status => {
  switch (bot_status) {
    case 'Pending':
      return 'Pending';
    case 'Placed With BestBuy':
    case 'Synced Placed With BestBuy':
      return 'Placed With BestBuy';
    case 'Placed With Giga':
    case 'Synced Placed With Giga':
      return 'Placed With Giga';
    case 'Being Processed':
    case 'Synced Being Processed':
      return 'Being Processed';
    case 'Ready For Pickup':
    case 'Synced Ready For Pickup':
      return 'Ready For Pickup';
    case 'Fulfillment Confirmed':
    case 'Synced Fulfillment Confirmed':
      return'Fulfillment Confirmed';
    case 'Lease Initiated':
    case 'Synced Lease Initiated':
      return 'Lease Initiated';
    case 'Lease Confirmed':
    case 'Synced Lease Confirmed':
      return 'Lease Confirmed';
    case 'Cancelled':
      return 'Cancelled';
    case 'Manual Verification Required':
      return 'Manual Verification Required';
    case 'preliminary':
      return 'preliminary';
    case 'final_id_verification':
      return 'Final ID Verification';
    case 'final_customer_request':
      return 'Final By Customer Request';
    case 'lease_terminated':
      return 'lease terminated';
    default: return 'Pending';
  }
};

const StatusLabel = props => {
  const { status, name } = props;

  return (
    <Label
      color={PAYMENT_STATUS_COLORS[botStatusMasquerade(status)]}
      variant="outlined"
      name={name || ''}
    >
      {botStatusMasquerade(status)}
    </Label>
  );
};

StatusLabel.propTypes = {
  status: PropTypes.string.isRequired
};

export default StatusLabel;
