import React, { useCallback } from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
      background: '#f4f6f8'
    }
  },
  grid: {
    textAlign: 'center',
  },
  noOrdersRed: {
    color: 'red',
    '&:hover': {
      cursor: 'default',
      textDecoration: 'none'
    }
  }
}));

export const CustomerItem = (props) => {
  const { customer, onClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLinkClick = useCallback(() => {
    history.push(`/orders?q=${encodeURIComponent(customer?.email) || ''}&page=0`);
  }, [history, customer]);

  const handleClick = useCallback(() => {
    dispatch({
      type: 'CREATE_ORDER_SET_VALUE',
      payload: { key: 'current_customer', value: customer }
    });
    onClose();
  }, [dispatch, customer, onClose]);

  return (
    <Grid
      className={classes.container}
      container
      mt={1}
      onClick={handleClick}
      spacing={2}
    >
      <Grid item xs={2}>
        <Person fontSize={'large'} />
      </Grid>
      <Grid item xs={7}>
        <Typography variant="subtitle1">{`${customer?.first_name} ${customer?.last_name}`}</Typography>
        <Typography variant="body2">{customer?.company}</Typography>
        <Typography variant="body2">{customer?.email}</Typography>
      </Grid>
      <Grid className={classes.grid} item xs={3}>
        <Link
          className={!customer?.isOrderHistory && classes.noOrdersRed}
          component="button"
          disabled={!customer?.isOrderHistory}
          onClick={handleLinkClick}
          variant="body2"
        >{customer?.isOrderHistory ? 'View order history' : 'No orders'}</Link>
      </Grid>
    </Grid>
  );
};

CustomerItem.propTypes = {
  customer: PropTypes.object,
  onClose: PropTypes.func
};
