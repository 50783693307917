import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {toastHttpError} from './common';
// import {notifyWarn} from './common';

export const useGetMarketingReport = () => {

  // useEffect(function() {
  //   notifyWarn('From Date and To Date params are required. Please open Filter panel and set params.');
  // }, []);

  const prepareParams = useCallback(({fromDate, toDate, oid, pid, sid, eid, type, leaseProvider, storeType}) => {
    const params = {};
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString());}
    if (oid) { params.oid = oid;}
    if (pid) { params.pid = pid;}
    if (sid) { params.sid = sid;}
    if (eid) { params.eid = eid;}
    if (type) { params.type = type;}
    if (leaseProvider) { params.lease_provider = leaseProvider;}
    if (storeType) { params.store_type = storeType;}

    return params
  }, []);

  const [ledgerList, setLedgerList] = useState([]);
  const [ext, setExt] = useState({});
  const [ledgerListCount, setLedgerListCount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((ledgerListPromise) => {
    setLedgerListCount(0);
    setLedgerList([]);
    setInProgress(true);

    ledgerListPromise.then(response => {

      if (response?.data?.count) {
        setLedgerListCount(response.data.count);

        if (response?.data?.data) {
          setLedgerList(response.data.data);
        }
        if (response?.data?.ext) {
          setExt(response.data.ext);
        }
      } else {
        setLedgerListCount(0);
        setLedgerList([]);
        setExt({});
      }

      setInProgress(false);
    }).catch(response => {
      setInProgress(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      setLedgerListCount(0);
      setLedgerList([]);
      toastHttpError(response);
    });
  }, []);

  const { fromDate, toDate, type, oid, pid, sid, eid, leaseProvider, storeType } = useFiltersSearch();

  useEffect(function() {

    if (fromDate) {

      handleResponse(axios().get('/reports/marketing', {
        params: {
          ...prepareParams({ fromDate, toDate, type, oid, pid, sid, eid, leaseProvider, storeType })
        }
      }));

    } else {
      setLedgerListCount(0);
      setLedgerList([]);
      setExt({});
    }
  }, [fromDate, toDate, type, oid, pid, sid, eid, leaseProvider, storeType, handleResponse, prepareParams, setLedgerListCount, setLedgerList, setExt, dataReload]);

  return {
    ledgerList,
    ledgerListCount,
    ext,
    inProgress,
    dataReloadInit
  };
};