import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  address: {
    textTransform: 'capitalize',
  },
  mainText: {
    color: colors.blue[900],
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
  columnTable: {
    width: '30%'
  },
  data: {
    overflowWrap: 'anywhere',
  }
}));

export const CustomerAuthInfo = props => {

  const { customer, className, /* isEditMode,*/ ...rest } = props;

  const classes = useStyles();
  const [channel, setChannel] = useState('');
  const [labelChannel, setLabelChannel] = useState('Channel');

  useEffect(() => {
    if (customer?.auth?.last_auth_through === 'email') {
      setChannel(customer?.auth?.email);
      setLabelChannel('Email');
    }
    else if (customer?.auth?.last_auth_through === 'phone') {
      setChannel(customer?.auth?.phone);
      setLabelChannel('Phone');
    }
  }, [customer, setChannel]);


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardHeader title="Last Auth" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>

            {customer?.auth?.last_auth_timestamp && <TableRow className={classes.tableSelected}>
              <TableCell >
                <Typography variant="subtitle2">
                  Last auth date
                </Typography>
              </TableCell>
              <TableCell>
                <div>{moment(customer?.auth?.last_auth_timestamp).format('DD MMM YYYY  h:mm:ss a') || ''}</div>
              </TableCell>
            </TableRow>}

            {customer?.auth?.last_auth_through && <TableRow  /*className={classes.tableSelected}*/>
              <TableCell className={classes.columnTable} >
                <Typography variant="subtitle2">
                  Last auth through
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.data}>{customer?.auth?.last_auth_through || ''}</div>
              </TableCell>
            </TableRow>}

            {channel && <TableRow>
              <TableCell className={classes.columnTable} >
                <Typography variant="subtitle2">
                  {labelChannel}
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.data}>{channel || ''}</div>
              </TableCell>
            </TableRow>}

            {customer?.auth?.last_auth_ip_address && <TableRow>
              <TableCell >
                <Typography variant="subtitle2">
                  IP address
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer?.auth?.last_auth_ip_address || ''}</div>
              </TableCell>
            </TableRow>}

            {customer?.auth?.last_auth_country_code && <TableRow>
              <TableCell >
                <Typography variant="subtitle2">
                  Country code
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer?.auth?.last_auth_country_code || ''}</div>
              </TableCell>
            </TableRow>}

            {customer?.auth?.store_type && <TableRow>
              <TableCell >
                <Typography variant="subtitle2">
                  Store type
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer?.auth?.store_type || 'electronics'}</div>
              </TableCell>
            </TableRow>}

          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

CustomerAuthInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

